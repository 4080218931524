$filter-desktop-width: 23%;
$filter-tablet-width: 35%;
$filter-mobile-width: 75%;
$filter-option-font-size: 13px;
$filter-option-mobile-font-size: 14px;

#grid-container {

    overflow: hidden;
    padding: 20px 40px;
    max-width: 1440px;
    min-height: 2500px;

    &.sportLanding {
        min-height: auto;
    }

    @media($screen-sm-max) { padding: 0; min-height: calc(100vh - 415px); }

    .nts-header-container {

        .nts-header-nav {
            min-height: 62px;
            vertical-align: middle;
            position: relative;
            margin-top: 10px;

            @media($screen-sm-max) { display: none; }

            h3 {
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-weight: bold;
                font-size: 22px;
                @media($screen-md-min) {
                    position: absolute;
                    left: 0;
                }
            }

            ul {
                line-height: 22px;

                @media($screen-md-min) {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    text-align: center;
                }

                li {
                    list-style: none;
                    display: inline-block;
                    padding-right: 20px;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                    font-weight: bold;
                    a, a:visited, a:focus {
                        color: $black;
                    }
                    a:hover {
                        color: $dark-gray;
                    }
                    a.selected {
                        text-decoration: underline;
                    }
                }
            }
        }

        .nts-banner-image {
            position: relative;
            min-height: 190px;

            @media($screen-sm-min) {
                height: auto;
            }

            img {
                width: 100%;
                @media($screen-sm-min) {
                    object-fit: cover;
                    height: auto;
                    max-height: unset;
                    min-width: 768px;
                }
            }

            .nts-banner-copy-container {
                position: absolute;
                left: 0;
                width: 50%;

                &.top { top: 0; bottom: auto; }
                &.bottom { bottom: 0; top: auto; }

                &.dark { color: #000; }
                &.light { color: #FFF; }

                span {
                    display: inline-block;
                    padding: 0;
                    margin: 0;
                }

                .title {
                    font-family:  $font-header;
                    text-transform: uppercase;
                    white-space: nowrap;
                    overflow-x: visible;
                }

                .desc {
                    font-family: $font-primary;
                    padding-top: 5px;
                }

                @media (min-width: 1200px) {
                    padding: 36px;
                    .title { font-size: 42px; line-height: 42px; }
                    .desc { font-size: 18px; line-height: 18px; }
                }
                @media (min-width: 992px) and (max-width: 1199px) {
                    padding: 24px;
                    .title { font-size: 36px; line-height: 36px; }
                    .desc { font-size: 16px; line-height: 16px; }
                }
                @media (min-width: 768px) and (max-width: 991px) {
                    display: inline-block;
                    padding: 18px;
                    .title { font-size: 28px; line-height: 28px; }
                    .desc { font-size: 14px; line-height: 14px; }
                }
                @media (max-width: 767px) {
                    display: inline-block;
                    text-align: center;
                    padding-bottom: 10px;
                    .title { font-size: 28px; line-height: 28px; }
                    .desc { display: none; }
                }
            }
        }

        .nts-banner-video { }

        .nts-banner-image-video {
            @extend .nts-banner-image;

            .nts-banner-copy-container {
                padding: 20px 30px;
            }

            .image-col {
                padding-right: 2px;
                display: inline-block;
                width: 61%;
                vertical-align: top;
                img {
                    height: 297px;
                    min-width: auto;
                }
            }
            .video-col {
                padding-left: 2px;
                display: inline-block;
                width: 39%;
                vertical-align: top;
                .video-wrapper {
                    background-color: #000;
                    box-sizing: border-box;
                    color: #fff;
                    display: block;
                    font-family: serif, Times, "Times New Roman";
                    font-size: 10px;
                    height: 297px;
                    line-height: 1;
                    padding-top: 56.25%;
                    position: relative;
                    user-select: none;
                    -webkit-user-select: none;
                    vertical-align: top;
                }
                iframe {
                    border-style: none;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            @media($screen-xl-max) {
                .image-col { width: 61%; }
                .video-col { width: 39%; }
            }
            @media($screen-lg-max) {
                .image-col {
                    width: 55%;
                    img { height: 275px; }
                }
                .video-col {
                    width: 45%;
                    .video-wrapper { height: 275px; }
                }
            }
            @media($screen-md-max) {
                .image-col {
                    width: 50%;
                    img { height: 250px; }
                }
                .video-col {
                    width: 50%;
                    .video-wrapper { height: 250px; }
                }
            }
            @media($screen-sm-max) {
                .image-col { display: none; }
                .video-col {
                    width: 100%; padding: 0;
                    .video-wrapper { height: auto; }
                }
            }

        }
    }

    #nts-gridwall-scroll-container {

        display: inline-block;
        width: 100%;
        margin: 40px 0;

        @media($screen-sm-max) {
            margin: 10px 0 0 0;
        }

    }

    .grid-filter-container {
        background-color: white;
        box-sizing: border-box;
        padding: 20px;
        margin-bottom: 40px;

        @media($screen-md-min) {
            padding: 0;
        }

        @media($screen-md-max) {
            padding: 0;
            margin-bottom: 40px;
            position: fixed;
            width: $filter-mobile-width;
            right: 100%;
            top:0;

            bottom: 0;
            transition: 200ms right cubic-bezier(1, 0, 0, 1);
            z-index:1000;
            overflow-y: scroll;
            &.open {
                right: 25%;
                z-index: 16000001;
                @media($screen-sm-min) {
                    right: 65%
                }
            }
        }
        @media($screen-sm-min) {
            display: inline-block;
            width: $filter-tablet-width;
        }
        @media($screen-lg-min) {
            padding: 0;
            width: $filter-desktop-width;
        }

        .spacer { margin-top: 40px; }

        .nts-filter-scroll-container {
            overflow: auto;
            height: auto;
            padding-top: 10px;
        }

        .nts-sport-filters-header {
            margin-bottom: 20px;
            padding: 20px;
            border-bottom: 1px solid $accent-gray;
            font-weight: bold;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

            @media($screen-sm-max) {
                padding-left: 20px;
            }

            .xl {
                font-size: 18px;
                span {
                    color: $med-gray;
                }
            }
        }

        .nts-sport-filters-clear-all {
            margin-top: 20px;
            padding-right: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: bold;
            a, a:visited, a:focus {
                color: $black;
            }
            a:hover {
                color: $dark-gray;
            }
        }

        .grid-filter-section {
            margin-top: 20px;
            padding: 0 20px;

            @media($screen-sm-max) {
                padding: 0;
            }

            .grid-filter-title {
                font-size: 24px;
                margin-bottom: 20px;
                text-transform: uppercase;
                line-height: 30px;
                padding-bottom: 20px;
                border-bottom: 1px solid $accent-gray;

            }
            .grid-filter-group {
                padding-bottom: 20px;
                border-bottom: 1px solid $accent-gray;
                margin-bottom: 20px;
                font-family: $font-primary;
                @media($screen-sm-max) {
                    padding: 0 20px 20px;
                }
                .grid-filter-type {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 24px;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                    text-transform: capitalize;
                    position: relative;
                    cursor: pointer;
                    &:before {
                        content: url('../../img/SVG/Chevron_down.svg');
                        position: absolute;
                        top: 1px;
                        right: 2px;
                    }
                    @media($screen-sm-max) {
                        font-size: $filter-option-mobile-font-size;
                        font-weight: bold;
                        font-family: $font-primary;
                        text-transform: capitalize;
                        margin-bottom: 10px;
                        &:before {
                            display:none;
                        }
                    }
                }
                .grid-filter-group-sub-title {
                    margin-bottom: 5px;
                    font-size: $filter-option-font-size;
                    @media($screen-sm-max) {
                        font-size: $filter-option-mobile-font-size;
                    }
                }
                .grid-filter-options {
                    display: none;
                    @media($screen-sm-max) {
                        display:block;
                    }
                    .grid-filter-option {
                        text-transform: initial;
                        line-height: inherit;
                        font-family: $font-primary;
                        font-size: $filter-option-font-size;
                        cursor: pointer;
                        margin-bottom: 7px;
                        font-weight: normal;
                        display: block;
                        user-select: none;
                        @media($screen-sm-max) {
                            font-size: $filter-option-mobile-font-size;
                            margin-bottom: 12px;
                        }
                        .nike-check {
                            margin: 2px 10px 0 0;
                            position: relative;
                            top: 2px;
                            @media($screen-sm-max) {
                                float:right;
                                margin: 0;
                                &:after {
                                    border:none;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                &.open {
                    .grid-filter-type {
                        margin-bottom: 10px;
                        &:before {
                            content: url('../../img/SVG/Chevron_up.svg');
                        }
                    }
                    .grid-filter-options {
                        display: block;
                    }
                }
            }

            .nts-nav-filters {
                border-bottom: 1px solid #e5e5e5;
                min-height: 62px;
                vertical-align: middle;
                position: relative;
                margin-top: 10px;
                margin-bottom: 20px;

                ul {
                    padding-left: 0;
                }

                @media($screen-sm-max) { display: none; }

                h3 {
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                    font-weight: bold;
                    font-size: 22px;
                    @media($screen-md-min) {
                        position: absolute;
                        left: 0;
                    }
                }

                ul {
                    line-height: 22px;
                    margin-bottom: 20px;

                    @media($screen-md-min) {
                        width: 100%;
                    }

                    li {
                        list-style: none;
                        padding-right: 20px;
                        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                        font-weight: bold;
                        a, a:visited, a:focus {
                            color: $black;
                        }
                        a:hover {
                            color: $dark-gray;
                        }
                        a.selected {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .grid-filter-mobile-controls {
            padding: 20px;
            font-family: $font-primary;
            font-weight: 700;
            position: fixed;
            bottom: 0;
            width: $filter-tablet-width;
            background-color: white;
            border-top: 1px solid $accent-gray;
            .mobile-control {
                width: 50%;
                float:left;
                text-align: center;
                &:first-child {
                    border-right: 1px solid $accent-gray;
                }
            }
            @media (min-width: 768px) and (max-width: 991px) {
                width: $filter-tablet-width;
            }
            @media (max-width: 767px) {
                width: $filter-mobile-width;
            }
        }
    }
    .grid-filter-overlay {
        position: fixed;
        z-index: 999;
        background-color: black;
        opacity: 0;
        left:0;
        right:100%;
        bottom:0;
        top:0;
        transition: opacity 500ms;
        &.open {
            right: 0;
            opacity: 0.8;
        }
    }
    .grid-results-container {
        padding: 20px;
        display: inline-block;
        vertical-align: top;
        width: 100%;


        @media($screen-md-min) {
            width: calc(100% - #{$filter-tablet-width});
        }
        @media($screen-lg-min) {
            width: calc(100% - #{$filter-desktop-width});
        }
        @media($screen-sm-max) {
            padding-top: 0;
        }
        .grid-header {

            .grid-header-title {
                font-size: 24px;
                padding-bottom: 20px;
                text-transform: uppercase;
                line-height: 30px;
                .grid-header-count {
                    color: $med-gray;
                    margin-left: 10px;
                }
                @media($screen-sm-max) {
                    text-align: center;
                    font-size: 20px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            }
            .grid-header-filter-crumbs {
                .filter-crumb {
                    font-family: $font-primary;
                    margin-right: 20px;
                    cursor: pointer;
                    display: inline;
                    font-size: 12px;
                    I {
                        margin-right: 3px;
                        font-weight: bold;
                        font-size: 16px;
                    }
                }
                .clear-all {
                    font-family: $font-primary;
                    margin-right: 20px;
                    cursor: pointer;
                    display: inline;
                    text-decoration: underline;
                    font-size: 12px;
                }
            }
            .grid-header-mobile-header {
                overflow: hidden;
                font-family: $font-primary;
                padding: 15px 20px 0;
                margin: 0 -20px;
                font-size: 14px;
                cursor: pointer;
                .grid-filter-toggle {
                    float:left;
                }
                .grid-count-indicator {
                    float:right;
                }
                .nts-sport-filters-header {
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    font-weight: bold;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

                    @media($screen-md-max) {
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }

                    .xl {
                        font-size: 18px;
                        span {
                            color: $med-gray;
                        }
                    }
                }
                .nts-sport-variance {
                    .xl, .grid-filter-toggle {
                        line-height: 16px;
                    }
                    .xl { float: left; margin-top: 5px; }
                    .grid-filter-toggle { float: right; }

                    ul {
                        line-height: 22px;
                        display: block;
                        padding-top: 20px;
                        width: 100%;
                        overflow: auto;
                        white-space: nowrap;
                        margin: 0;
                        scrollbar-width:none;

                        li {
                            list-style: none;
                            display: inline-block;
                            padding-right: 20px;
                            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                            font-weight: bold;
                            a, a:visited, a:focus {
                                color: $black;
                            }
                            a:hover {
                                color: $dark-gray;
                            }
                        }
                    }
                }
            }
        }
        .grid-cells-container { clear: both; }
        .grid-results-item-wrapper {
            height: 340px;
            position: relative;

            @media($screen-sm-max) {
                height: 322px;
            }

            .grid-cell-contents {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                padding: 20px;
                border: 1px solid white;
                background-color: white;
                &.active {
                    border: 1px solid $accent-gray;
                    z-index: 2;
                    .grid-cell-color-options {
                        display: block
                    }
                }
                .grid-results-image {
                    text-align: center;
                    cursor: pointer;
                    position: relative;
                    height: 200px;
                    @media (min-width:992px) {
                        min-height: 200px;
                    }
                    @media (max-width:992px) and (min-width:0px) {
                        min-height: 100px;
                        height: auto;
                        width: auto;
                    }
                    img {
                        padding: 5px;
                    }
                }
                .grid-cell-color-options {
                    height: 55px;
                    overflow: hidden;
                    display: none;
                    text-align: center;
                    position: relative;
                    .grid-cell-color-option {
                        padding: 5px;
                        display: none;
                        cursor: pointer;
                        user-select: none;
                        &.showing {
                            display: inline-block;
                        }
                    }
                    .grid-cell-thumb-toggle {
                        height: 45px;
                        width: 45px;
                        display: inline-block;
                        padding: 5px;
                        color: $med-gray;
                        cursor: pointer;
                        z-index: 1;
                        &:hover {
                            color: $black;
                        }
                        &.left {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            text-align: left;
                        }
                        &.right {
                            position: absolute;
                            right: 0;
                            top:4px;
                            text-align: right;
                        }
                        I {
                            font-size: 28px;
                        }
                    }
                }
                .grid-cell-info {
                    font-family: $font-primary;
                    position: relative;
                    margin-top: 10px;
                    height: 92px;
                    text-align: left;
                    font-size: 12px;
                    cursor:pointer;
                    @media($screen-sm-max) {
                        font-size: 12px;
                    }
                    .grid-cell-style-details {
                        overflow: hidden;
                        line-height: 20px;
                        @media($screen-sm-max) {
                            font-size: 11px;
                            line-height: 20px;
                        }
                        .grid-cell-style-number {
                            float: right;
                        }
                        .inline-color-wheel {
                            top: 2px;
                            margin-right: 3px;
                            @media($screen-sm-max) {
                                top: 2px;
                            }
                        }
                        .grid-cell-customize-it {
                            text-align:left;
                            float:left;
                            user-select: none;
                            @media($screen-sm-max) {
                                font-weight: 400;
                            }
                        }
                        .grid-cell-color-count {
                            left: 14px;
                            text-align: left;
                            z-index: 10;
                            color: #000;
                            float:left;
                            @media($screen-sm-max) {
                                font-weight: 400;
                            }
                        }
                    }
                    .grid-cell-product-name {
                        border-top: 1px solid $accent-gray;
                        display: block;
                        overflow: hidden;
                        font-weight: 700;
                        text-overflow: ellipsis;
                        padding: 3px 0;
                        max-height: 55px;
                        @media($screen-sm-max) {
                            font-weight: 400;
                            max-height: 37px;
                        }
                    }

                    .grid-cell-product-sustainable {
                        color: $nike-orange;
                    }

                    .grid-cell-product-price,
                    .grid-cell-shipby-date {
                        color: $dark-gray;
                    }
                    .grid-cell-shipby-date {
                        font-style: italic;
                    }
                }
            }
        }

        .nts-slp-no-results {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $dark-gray;

            .heading {
                font-size: 22px;
                line-height: 2;
            }
        }
    }

    .bottom-sub-nav {
        margin-bottom: 10px;
        @media($screen-sm-max) {
            margin: 0;
            margin-top: 25px;
            padding: 0 10px;
        }

        .bottom-sub-nav-col {
            margin-bottom: 40px;
            position: relative;
            @media($screen-sm-max) {
                padding: 0 10px;
                margin-bottom: 20px;
            }

            &:after {
                content: ' ';
                height: 10px;
                left: 20px;
                right: 20px;
                bottom: 0;
                background-color: $accent-gray;
                position: absolute;
                @media($screen-sm-max) {
                    left: 10px;
                    right: 10px;
                }
            }
            @media($screen-md-min) {
                margin-bottom:0;
                border-right: 1px solid $accent-gray;
            }

            &:last-of-type {
                border-right: 0 solid transparent;
            }
            .sub-nav-header {
                display: flex;
                font-size: 16px;
                justify-content: center;
                margin-bottom: 20px;
                text-transform: uppercase;
            }
            .sub-nav-item {
                font-size: 12px;
                font-family: $font-primary;
                color: $dark-gray;
                margin-bottom: 10px;
                text-decoration: underline;
                A {
                    cursor:pointer;
                    color: $dark-gray;
                    &:hover, &:active {
                        color: $nike-orange;
                    }

                }
                @media($screen-md-min) {
                    text-decoration: none;
                }
                &:last-of-type {
                    padding-bottom: 50px;
                }
            }

            .sub-nav-shop-btn {
                margin-top: 20px;
                margin-bottom: 30px;
                margin-left: 15px;
            }
        }
    }
}
