#nts-dealer-share-page {

  h1.title {
    margin-bottom: 10px !important;
  }

  div.admin-tabs-content {
    padding: 20px !important;
    border: none;

    .nts-member-count {
      float: left;
      line-height: 30px;
      font-weight: bold;
    }

    .ReactTable.nts-table {
      .rt-table {
        .rt-thead {
          color: #000;
          background-color: #FFF;
          .rt-th {
            border-style: solid;
            border-width: 1px 1px 1px 0;
            border-color: #E5E5E5;
            border-collapse: collapse;
            &:first-child {
              border-left: 1px solid #E5E5E5;
            }
          }
        }
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {

              &:hover {
                background-color: #FFF;
                cursor: default;
              }

            }
          }
        }
      }
    }

    .user-impersonate-wrapper {
      position: relative;

      .user-impersonate-icon::before {
        width: 30px;
        height: 30px;
        left: calc(50% - 15px);
        cursor: pointer;
      }

    }

  }

}