.nts-basic-form {
  margin: 0;

  label {
    display: block;
    width: 300px;
    margin-top: 30px;
    &.horizontal {
      margin-top: 10px;
    }
    span:not([class]) {
      display: block;
    }
    input, textarea, select {
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: 1px solid $med-gray2;
      width: 100%;
      min-height: 30px;
      height: 40px;
      margin: 0;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: normal;
      color: $black;
    }
    textarea {
      height: 80px;
    }
  }
  button {
    width: 100px;
    margin-top: 15px;
  }
  .form-error {
    color: $error-red;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    &.form-input {
      border: 1px solid $error-red;
    }
  }

  .nts-basic-form-section {
    display: inline-block;

    &.form-section-spacer {
      margin-right: 40px;
    }

  }

  @media($screen-sm-max) {
    label {
      width: 100%;
    }
    .nts-basic-form-section {
      width: 100%;
      margin: 0;
    }
  }

  .Select {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $black;
    .Select-placeholder,
    .Select-input input {
      font-size: 15px;
      font-weight: normal;
      border: 0;
    }
    .Select-value {
      .Select-value-label {
        font-weight: normal;
      }
    }
    .Select-menu-outer {
      font-weight: normal;
    }
  }

}