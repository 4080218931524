#nts-announcement-banner-carousel-container {
  #nts-announcement-banner-carousel-wrapper {
    overflow: hidden;
    position: relative;
    display: block;
    text-align: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #F5F5F5;

    #nts-announcement-banner-carousel {
      position: relative;
      overflow-x: visible;

      .nts-announcement-banner-node {
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        a {
          text-decoration: underline;
          color: $black;
        }
      }

      .nts-announcement-banner-button {
        height: 100%;
        display: flex;
        align-items: center;
        color: $black;

        &.prev-banner {
          justify-content: right;
        }

        &.next-banner {
          justify-content: left;
        }
      }

      @media($screen-sm-max) {
        .nts-announcement-banner-node {
          padding: 0 44px;
          min-height: 60px;
          align-content: center;
        }

        .carousel-control {
          width: 5%;
        }
      }
    }
  }
}