/*********************/
/****** GENERAL ******/
/*********************/

.container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

@media ($screen-md-min) {
  .container {
    padding-left: 45px;
    padding-right: 45px;
  }
}
.row, .slider-nav, .slider-nav-popup, .slider-carousel, .slider-option, .md-list-option {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}

@media (min-width: 576px) {
  .row, .slider-nav, .slider-nav-popup, .slider-carousel, .slider-option, .md-list-option {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media (min-width: 768px) {
  .row, .slider-nav, .slider-nav-popup, .slider-carousel, .slider-option, .md-list-option {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media (min-width: 992px) {
  .row, .slider-nav, .slider-nav-popup, .slider-carousel, .slider-option, .md-list-option {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media (min-width: 1200px) {
  .row, .slider-nav, .slider-nav-popup, .slider-carousel, .slider-option, .md-list-option {
    margin-right: -20px;
    margin-left: -20px;
  }
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding: 0 20px;
}

.jumbotron {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0;
  margin-bottom: 50px;
  position: relative;
}

/**********************/
/****** CAROUSEL ******/
/**********************/
.carousel {
  overflow:hidden;
  .carousel-indicators {
    margin-left: auto;
    margin: 0 auto 0 auto;
    top: auto;
    right: auto;
  }
  .carousel-control {
    height: auto;
    margin-top:0;
    font-weight: inherit;
    line-height: inherit;
    background-color: transparent;
    background-image: none;
    border: 0 solid transparent;
    border-radius: 0;
  }
}

/********************/
/****** MODALS ******/
/********************/

.modal-dialog {


  .modal-content {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    overflow: auto;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    margin: auto;

    .modal-header {
      .modal-title {
        text-transform: uppercase;
        margin-bottom: 15px;
        line-height: 1.5;
        text-align:center;
      }
      .close {
        position: absolute;
        top:10px;
        right:15px;
        @media($screen-sm-max) {
          top: 2px;
        }
      }
    }

    .modal-footer {
      background: none;
      box-shadow: none;
    }

  }

}






/*******************/
/****** FORMS ******/
/*******************/
.form-group {

  margin-bottom: 0;

  label {
    font-weight: normal;
    text-transform: initial;
  }

  .form-control, select.form-control {
    font-size: 14px;
    height: 40px;
    padding: 0.25rem 0.25rem 0.25rem 15px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-family: "Helvetica", Arial, sans-serif;
  }

}
/* FORM CONTROLS OUTSIDE FORM GROUPS */
.form-control, select.form-control {
  font-size: 14px;
  height: 40px;
  padding: 0.25rem 0.25rem 0.25rem 15px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-family: "Helvetica", Arial, sans-serif;
}


/*********************/
/****** BUTTONS ******/
/*********************/
.btn {
  font-family: $font-secondary;
  text-transform: uppercase;
  font-size: 15px;
  height: 40px;
  padding: 0 20px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: none;
  @media($screen-sm-max) {
    padding: 0 15px;
  }
}

.btn-primary {
  background-color: $black;
  border-color: $black;
  color: $white;
  background-image: none;
  box-shadow: none;
}

.btn-primary:focus {
  background-color: $black;
  border-color: $black;
  background-image: none;
  box-shadow:none;
}

.btn-primary:hover {
  background-color: $black;
  border-color: $black;
  opacity: 0.95;
}

.btn-primary:disabled {
  background-color: $black;
  border-color: $black;
  opacity: 0.5;
}

.btn-default {
  background-color:$white;
  border-color: $accent-gray;
  background-image: none;
  text-shadow: none;
  color: $black;
}

.btn-default:focus {
  background-image: none;
  box-shadow: none;
}

.btn-default:hover {
  background-color: $light-gray;
}

.btn-default:disabled {
  color: #707070;
  opacity: 0.5;
}

.btn-warning {
  background-color: #DDDDDD;
  border-color: #DDDDDD;
  background-image: none;
  text-shadow: none;
  color: #FA5400;
}

.btn-warning:focus {
  background-color: #DDDDDD;
  border-color: #DDDDDD;
  background-image: none;
  text-shadow: none;
  box-shadow: 0 0 0 2px rgba(135, 135, 135, 0.5);
}

.btn-warning:hover {
  background-color: #DDDDDD;
  border-color: #DDDDDD;
  color: #ffffff;
  text-shadow: none;
}

.btn-info2 {
  background-color: #FFFFFF;
  border-color: #DDDDDD;
  background-image: none;
  text-shadow: none;
  color: #000000;
}

.btn-info2:hover {
  color: #000000;
  border-color: #DDDDDD;
}

.btn-info2:focus {
  color: #000000;
  box-shadow: none;
}

.btn-block + .btn-block {
  margin-top: 0;
}

.btn-toolbar {
  margin: 0;
  button  {
    margin-left: 5px;
    margin-right: 5px;
  }
}

button.close {
  font-size: 20px;
  font-weight: normal;
  z-index: 1000;
}

.btn-group {
  margin-left: 0;
  .btn:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

.dropdown {
  .caret {
    float: right;
    top: 2px;
    position: relative;
    margin-left: 1em;

    &:after {
      font-family: $font-nike-glyphs;
      content: "\E607";
      position: absolute;
      font-size: 12px;
    }

  }
}

.dropdown-menu {
  border-radius: 2px;
  font-family: $font-primary;
  box-shadow: none;
  padding: 15px 0;
  LI {
    a {
      padding: 5px 20px;
      &:hover, &:active, &:visited, &:focus {
        background-image: none;
        background-color: $accent-gray;
        color: $black;
      }
    }
  }
}

/******************/
/****** NAV ******/
/******************/
.nav-tabs {
  li {
    a, a:focus, a:hover {
      border: none;
      background: #ffffff;
      color: #000000;
      padding: 8px 15px;
      font-size: 16px;
      box-shadow: none;
      outline: none;
    }
  }

  li.active {
    a, a:focus, a:hover {
      border: none;
      border-bottom: 2px solid #000000;
      padding: 8px 15px 7px;
      color: #000000;
      box-shadow: none;
      outline: none;
    }
  }
}

/*********************/
/****** POPOVER ******/
/*********************/
.popover {
  .popover-title {
    font-family: TradeGothicCondensed, TradeGothic, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 10px 14px 8px 14px;
  }

  .popover-content {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
}

.tooltip-inner {
  background-color: $near-black;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
}

.tooltip-arrow {
  background-color: #FFF !important;
  border-bottom-color: $dark-gray !important;
  border-top-color: $dark-gray !important;
  /*border-right-color: red !important;
  border-left-color: lawngreen !important;
  border-bottom-color: blue !important;*/
}

/******************/
/****** MISC ******/
/******************/
.header {
  display: block;
  padding: 0;
}

address, dl, ol, ul {
  margin: inherit;
}

.test-select {
  background-color: #ddd;
}

/*******************/
/****** MODAL ******/
/*******************/
.modal-option-section {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 15px;
  padding: 10px 0;
  width: 100%;
  font-size: 18px;
  font-family: "TradeGothic-Bold";
  text-align: left;
  text-transform: uppercase;
  color: #000000;
  &.no-border {
    border: none;
  }
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2061;
  display: none;
  overflow: hidden;
  outline: 0;
  margin: 0;
  border-radius: 0;
  border: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.3rem;
  outline: 0;
  LI {
    line-height: 1.4;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}



.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
  max-height: none;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #eceeef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media ($screen-lg-min) {
  .modal-dialog {
    max-width: 500px;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.nts-unite-auth-modal {
  overflow: auto;
  z-index: 3000;

  .nike-unite .nike-unite-component.nike-unite-checkbox label {
    margin-left: 0;
    padding-left: 26px;

    &.checkbox:before {
      margin-top: -13px;
    }
  }

  .nts-spinner-container {
    background: #2D2D2D;
    opacity: 0.8;
    padding: 5px;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    z-index: 1;
  }

  .modal-content {
    border-radius: 2px;
    overflow: visible;
    .modal-body {
      z-index: 2;
    }
  }
}

/************************************/
/****** WEB-UI BOOTSTRAP FIXES ******/
/************************************/
.col-sm-3, col-sm-6 {
  padding: 0 20px;
}

label {
  line-height: 20px;
}

select {
  width: 220px;
}

input[type="text"],
input[type="password"],
select {
  border: 1px solid #cccccc;
  padding: 4px 6px;
}

.caret {
  border-top: 0;
}

.modal-title {
  font-family: "TradeGothic", Helvetica, Arial, sans-serif;
  font-weight: 700;
  word-spacing: -0.05em;
  letter-spacing: -0.045em;
  line-height: 1;
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
}