@import '../global/variables.scss';

.react-autosuggest__container {
    font-family: $font-primary;
    display:inline;
    position: relative;
    &:before {
        position: absolute;
        left: 12px;
        top: -5px;
        font-family: "nike-glyphs"; 
        content: '\E63E'; 
        z-index: 1;
        font-size: 20px;
        pointer-events: none;
    }
    @media($screen-md-max) {
        width: 100%;
        height: 100%;
        &:before {
            font-size: 29px;
            top: -10px;
            left: 16px;
        }
    }
    input.react-autosuggest__input {
        padding:3px 5px 3px 35px;
        font-size: 13px;
        border-radius: 2px;
        border: 1px solid $med-gray2;
        height:38px;
        margin-bottom: 0;
        outline: 0;
        width: 50%;
        font-family: $font-primary;
        transition: 200ms width;
        @media($screen-md-max) {
            width: 100%;
            height: 100%;
            padding: 0;
            border: 0 solid transparent;
            box-shadow: none;
            color: white;
            &::placeholder{ 
                color: white;
            }
            &:focus {
                position: fixed;
                lefT:0;
                top:0;
                right:0;
                height: 69px;
                z-index: 2;
                color: $black;
                padding: 15px;
            }
        }
        &:focus {
            width: 100%;
            border-color: $med-gray2;
            box-shadow: none;
        }
    }
}

.react-autosuggest__suggestions-container--open {
    background-color: white;
    z-index: 10;
    border-right: 1px solid $light-gray2;
    border-left: 1px solid $light-gray2;
    border-bottom: 1px solid $light-gray2;
    text-align: left;
    padding-bottom: 5000px;
    padding-top: 10px;
    position: relative;
    top: 16px;
    @media($screen-md-max) {
        position:fixed;
        top: 70px;
        left:0px;
        right:0px;
        bottom:0px;
        opacity: 0.95;
    }
    ul {
        padding-left: 0;
    }
}
.react-autosuggest__suggestion{
    padding: 10px 20px;
    font-size: 13px;
    cursor: pointer;
    
    &:hover, &.react-autosuggest__suggestion--highlighted {
        background-color: $accent-gray;
    }
    .suggestion-content {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.react-autosuggest__input {
    transition: 0ms 100ms width;
}
.react-autosuggest__input--focused {
    width: 500px;
}