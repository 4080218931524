#nav-bar {
    top: 0px;
    width: 100%;
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 100;
    background-color: white;
    height: 110px;
    @media ($screen-md-max) {
        height: 70px;
    }
    .top-nav {
        font-family: $font-primary;
        font-size: 13px;
        border-bottom: 1px solid $med-gray2;
        height:40px;
        background-color: #22242b;
        &:after {
            content: ' ';
            clear: both;
        }
        .logo {
            height: 40px;
        }
        .nts-jock-tag {
            position: relative;
            width: 90px;
            height: 87px;
            cursor: pointer;
            display: block;
            z-index: 1000;
            &::after {
                cursor: pointer;
                height: 86px;
                width: 86px;
                content: "";
                background-image: url("../../img/SVG/NIKE_TEAM_Mobile.svg");
                background-color: #22242b;
                background-size: 105%;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                top: 0;
                left: 15px;
            }
            &.scheels {
                &::after {
                    cursor: pointer;
                    height: 86px;
                    width: 86px;
                    content: "";
                    background-color: #22242b;
                    background-size: 105%;
                    background-repeat: no-repeat;
                    background-position: center;
                    position: absolute;
                    top: 0;
                    left: 15px;
                    background-image: url("../../img/SVG/SCHEELS-NIKE-TEAM_Square.svg");
                }
            }
        }
        .muted-link {
            padding: 12px 30px;
            color: $med-gray;
            height: 40px;
            border-right: 1px solid $med-gray;
            display: block;
            text-decoration: none;
            @media ($screen-md-min) and (max-width:1130px) {
                padding: 12px 20px;
            }
            &:hover, :active {
                text-decoration: none;
            }
        }
        .user-link {
            color: $med-gray;
            padding: 12px;
            height: 40px;
            display: inline-block;
            cursor: pointer;
            &:hover, &:active, &:focus, &:visited {
                text-decoration: none;
            }
            @media ($screen-md-min) and (max-width:1130px) {
                padding: 12px 9px;
            }
            &.cart-link {
                margin-right:15px;
                font-size: 24px;
                padding: 3px 15px;
            }
            &.account-drop {
                padding: 0 5px;
                position: relative;

                i.account-link {
                    font-size: 20px;
                    padding-top: 5px;
                }
                span.account-link {
                    font-size: 13px;
                    display: inline-block;
                }
                .menu-option-container {
                    right: -60px;
                    transform: none;
                    left: inherit;
                    min-width: 170px;
                }
            }
            &.admin-link {
                cursor: pointer;
            }
            &.login-link {
                padding: 12px 25px;
            }
        }
    }
    .primary-nav {
        position: relative;
        border-bottom: 1px solid $med-gray2;
        height: 70px;
        background-color: #FFF;
        @media($screen-md-max) {
            background-color: #22242b;
        }
        .brand-container {
            display: none;
            @media($screen-md-max) {
                display: block;
                width: 70px;
                float:left;
                padding: 0px 2px;
                .brand {
                    font-size: 28px;
                    color: $black;
                    &:hover, &:active, &:focus, &:visited {
                        color: $black;
                    }
                }
            }

        }
        .mobile-nav-container {
            float:right;
            color: $black;
            .mobile-nav {
                display:block;
                position: fixed;
                left:101%;
                right:0;
                top:0;
                bottom: 0;
                z-index: 1000;
                min-height: 100%;
                transition: 300ms left cubic-bezier(1, 0, 0, 1);
                overflow-x:hidden;
                overflow-y:auto;
                background-color: #22242b;
                &.open {
                    left:0;
                }
                .mobile-option {
                    padding: 25px 25px;

                    font-family: $font-primary;
                    border-bottom: 1px solid $light-gray2;
                    background-color: $light-gray;
                    font-size: 16px;
                    display:block;
                    color: $black;
                    position: relative;
                    &:after {
                        content: "\E60C";
                        font-family: "nike-glyphs";
                        position: absolute;
                        right: 25px;
                        font-size: 18px;
                        font-weight: bold;
                        top: 24px;
                    }
                    .cta-back-icon {
                        position: relative;
                        top:-2px;
                        margin-right: 10px;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    &.account-option {
                        background-color:white;
                        color: $med-gray;
                        .profile-icon {
                            font-size: 24px;
                            line-height: 20px;
                            margin-right: 5px;
                        }
                    }
                    &.mobile-menu-header {
                        background-color: white;
                        color: $med-gray;
                        &:after {
                            content: " ";
                        }

                    }
                }
                .mobile-nav-side {
                    position: absolute;
                    left:0;
                    width: 40px;
                    top:0;
                    bottom: 0;
                    background-color: #22242b;
                    color: $med-gray;
                    font-size: 28px;
                    I {
                        position: absolute;
                        top: 17px;
                        left: -15px;
                        transition: 100ms left;
                    }
                }
                .main-options {
                    margin-left: 40px;
                    display:none;
                    &.open {
                        display:block;
                    }
                }
                .sub-options {
                    position: absolute;
                    right:0;
                    left:40px;
                    top:0;
                    bottom:0;
                    display:none;
                    overflow-y:scroll;
                    &.open {
                        display:block;
                    }
                }
            }
        }

        .nav-menu-container {
            position: absolute;
            left:50%;
            transform: translateX(-50%);
            margin: 0 auto;
            text-align: center;
            max-height: 70px;
            overflow: hidden;
        }

        .nts-nav-item {
            position: relative;
            display:inline-block;
            font-family: $font-secondary;
            padding: 15.5px 15px;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 28px;

            @media($screen-md-min) {
                padding: 27.5px 20px;
                font-size: 16px;
                line-height: 15px;
            }
            @media($screen-md-max) {
                color: $med-gray;
            }
            @media (max-width:1170px) and (min-width:993px) {
                padding: 27.5px 13px;
            }​

            a, a:visited, a:active {
                color: #000;

                .g72-cart {
                    color: #8D8D8D;
                }
            }

            p {
                font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
                font-size: 18px;
            }

            .nts-sub-nav{
                width: 360px;
                padding: 40px 35px;
                .nts-sub-nav-title {
                    font-family: $font-secondary;
                    text-transform: uppercase;
                    margin: 10px 0 15px 0;
                    font-size: 16px;
                    line-height: 15px;
                    text-align: center;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $med-gray2;
                }
                .nts-sub-nav-col {
                    float:left;
                    min-width: 80px;
                    &:first-of-type {
                        min-width:112px;
                    }
                    .nts-sub-nav-item {
                        color: $black;
                        white-space: nowrap;
                        display: block;
                        padding: 5px 0 0;
                        margin: 0 10px 5px;
                        font-family: $font-primary;
                        text-transform: initial;
                        font-size: 13px;
                        text-align: left;
                        &:hover {
                            text-decoration: underline;
                            color: $med-gray;
                        }
                    }
                }

            }
        }
        .searchbar-container {
            position: relative;
            float:right;
            padding: 16px 30px 16px 0;
            width: 25%;
            text-align: right;

            @media($screen-md-max) {
                width: 48px;
                padding: 0;
                height: 69px;
                background-color: #22242b;
                color: $med-gray;
                input {
                    background-color: #22242b;
                    color: #FFF;
                }
            }
            li {
                list-style: none;
            }

        }
    }

    .nts-simple-menu-toggle {
        position: relative;
        a {
            color: #000;
            &:hover, &:active, &:focus, &:visited {
                text-decoration: none;
            }
        }
        &:hover {
            .nts-simple-menu {
                display:block;
            }
        }
        .nts-simple-menu {
            display:none;
            position: absolute;
            top:100%;
            left: 50%;
            padding: 15px;
            font-size: 14px;
            transform: translateX(-50%);
            background-color:white;
            border-bottom: 1px solid $med-gray2;
            border-left: 1px solid $med-gray2;
            border-right: 1px solid $med-gray2;
            z-index: 51;
            .nts-simple-menu-item {
                display:block;
                color: $black;
                white-space: nowrap;
                padding: 5px 10px;
                font-family: $font-primary;
                font-size: 14px;
                &:hover {
                    color: $med-gray;
                    *, a {color: $med-gray !important;}
                }
                &.muted {
                    color: $med-gray;
                }
            }
            .nts-simple-menu-bar {
                border-color: $med-gray2;
                margin: 10px 0;
            }
        }
    }
    /* Navbar stuff */
    .nts-nav-bar-dropdown {
        width: 100vw;
        position: absolute;
        top: 70px;
        left: 0;
        z-index: 50;
        background-color: white;
        font-size: 14px;
        background-color:white;
        border-bottom: 1px solid $med-gray2;
        border-left: 1px solid $med-gray2;
        border-right: 1px solid $med-gray2;
        padding: 40px 35px;

        /*subnav stuff*/

        .nts-nav-bar-col {
            float:left;
            min-width: 80px;
            margin-right: 40px;
            padding: 0px 0px 0px 20px;
            &:first-of-type {
                min-width:112px;
            }
            .nts-nav-bar-item {
                color: $black;
                white-space: nowrap;
                display: block;
                padding: 5px 0 0;
                margin: 0 10px 5px;
                font-family: $font-primary;
                text-transform: initial;
                font-size: 13px;
                text-align: left;
                &:hover {
                    text-decoration: underline;
                    color: $med-gray;
                }
            }
        }
        .with-left-bar {
            border-left: 1px solid $med-gray2;
        }
        .nts-nav-bar-title {
            font-family: $font-secondary;
            text-transform: uppercase;
            margin: 0px 0px 0px 10px;
            font-size: 16px;
            line-height: 15px;
            text-align: left;
            padding-bottom: 5px;
            padding-top: 20px;
        }
        .nts-nav-bar-flex {
            display: flex;
            justify-content: center;
            width: 100%;
        }
        .nts-nav-bolded-item {
            font-family: $font-secondary;
            text-transform: uppercase;
            margin: 0px 0px 0px 10px;
            font-size: 16px;
            line-height: 15px;
            text-align: left;
            padding-bottom: 5px;
            padding-top: 15px;
            color: $black;
            white-space: nowrap;
            display: block;
        }
    }
}
