$black: #111111;
$near-black: #444;
$white: #ffffff;
$nike-orange: #FA5400;
$light-green: #99CC00;
$light-blue: #4CA5D2;
$dark-gray: #6D6D6D;
$med-gray: #8D8D8D;
$med-gray2: #c1c1c1;
$light-gray2: #DDDDDD;
$light-gray: #F7F7F7;
$accent-gray: #e5e5e5;
$red: #C91014;
$error-red: #fe0000;

$primary: $black;
$primary-action: rgba(0, 0, 0, .75);

$default: $light-gray;
$default-action: rgba(0, 0, 0, .25);

@value black: #{$black};
@value white: #{$white};
@value nikeOrange: #{$nike-orange};
@value medGray: #{$med-gray};
@value medGray2: #{$med-gray2};
@value lightGray: #{$light-gray};
@value darkGray: #{$dark-gray};

$screen-xl-max: "max-width: 1440px";
$screen-xl-min: "min-width: 1440px";
$screen-lg-max: "max-width: 1220px";
$screen-lg-min: "min-width: 1200px";
$screen-md-min: "min-width: 992px";
$screen-md-max: "max-width: 991px";
$screen-sm-min: "min-width: 768px";
$screen-sm-max: "max-width: 767px";

$font-header: "futuraregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-secondary: "TradeGothicCondensed", TradeGothic, Helvetica, Arial, sans-serif;
$font-primary: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-primary-bold: HelveticaBold, Arial, sans-serif;
$font-nike-glyphs: 'nike-glyphs';