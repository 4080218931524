.nts-covid-checkout-modal {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: rgba(0, 0, 0, 0.75);
  text-align: center;

  .nts-covid-checkout-dialog {
    max-width: 700px;

    .modal-content {

      .modal-header {
        .modal-title {
          margin-top: 45px;
          margin-bottom: 5px;
          font-size: 30px;
        }
        .close {
          font-size: 25px;
        }
      }
      .modal-body {
        line-height: 25px;
        padding: 20px;
        h4 {
          font-weight: bold;
          font-style: italic;
          font-size: 15px;
          text-transform: none;
        }
        p {
          font-size: 13px;
        }
        .warning-container {

        }
        .instruction-container {
          margin-top: 35px;
          li {
            font-size: 13px;
            line-height: 25px;
          }
        }
        .button-container {
          text-align: center;
          margin-top: 45px;
          margin-bottom: 0;
          button {
            max-width: 200px;
            margin: 0 auto;
          }

        }
      }
    }
  }

}

#nts-covid-msg-banner {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #F5F5F5;

  a {
    text-decoration: underline;
    color: $black;
  }

}

.nts-covid-message-container {
  min-height: calc(100vh - 295px);
  color: #000;
  font-family: TradeGothicCondensed, TradeGothic, Helvetica, Arial, sans-serif;
  max-width: 700px;
  text-align: center;

  h2 {
    margin: 30px 0 20px 0;
  }

  p {
    font-size: 16px;
    margin: 20px 0;
  }

  p.terms-text {
    font-family: $font-primary;
    font-size: 14px;
    line-height: 24px;
  }
}