@import '../../css/designFonts.scss';

.pdp-container {

  padding-top: 40px;
  margin-bottom: 40px;
  max-width: 1440px;
  min-height: calc(100vh - 335px);
  // padding-left: 45px;
  // padding-right: 45px;
  @media ($screen-xl-min) {
    .container {
      padding-left: 85px;
      padding-right: 85px;
    }
  }

  #nts-pdp-invalid-msg {
    text-align: center;
    display: none;
    margin-top: 300px;
    p {
      font-size: 24px;
    }
  }

  .xs-center-align {
    @media ($screen-sm-max) {
      text-align: center;
    }
  }

  //TODO: Move all separators to the same margin if possible and remove separate class declarations
  .separator {
    margin: 25px -20px !important;
    @media ($screen-sm-min) {
      margin: 25px 0 !important;
    }
  }
  .sticky {
    #pdp-image-pane, #pdp-details-panel {
      margin-top: 110px;
    }
  }
  .pdp-product-header {
    .product-title {
      font-family: $font-secondary;
      text-transform: uppercase;
      font-size: 32px;
      line-height: 32px;
    }
    .product-name-second {
      margin-top: 5px;
      font-size: 19px;
    }
    .product-sustainability-label {
      color: $nike-orange;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
      position: relative;
      height: 22px;
      line-height: 22px;
      margin-top: 5px;
      display: inline-block;
      p {
        margin-left: 24px;
      }
    }
    .product-date {
      color: $black;
      font-family: $font-primary;
      font-size: 14px;
      line-height: 18px;
      margin-top: 5px;
    }
    .product-category {
      color: $black;
      font-size: 14px;
      line-height: 18px;
      font-family: $font-primary;
      margin-bottom: 10px;
      @media($screen-sm-max) {
        margin-bottom: 15px;
      }
    }
    .product-price {
      font-size: 16px;
      font-family: $font-primary;
      text-align: right;
      margin-bottom: 5px;
      @media ($screen-sm-max) {
        text-align: center;
        margin-top: 10px;
      }

      .product-price-info {
        color: $black;
        margin-right: 5px;
        cursor: pointer;
        display: inline-block;
        font-size: 20px;
        line-height: 20px;
      }

    }
    .price-details {
      color: $med-gray;
      font-size: 14px;
      line-height: 14px;
      font-family: $font-primary;
      cursor: pointer;
      margin-top: 10px;

      I {
        margin-right: 5px;
        top: -1px;
        position: relative;
      }
    }

  }
  .product-desc {
    font-family: $font-primary;
    font-size: 15px;
    line-height: 18px;
    word-break: break-word;
    p {
      margin-bottom: 0;
    }
    /*!important is necessary to override DB values*/
    ul {
      margin: 15px 0;
      list-style-position: inside;
    }

    li {
      list-style-type: disc;
      margin: 10px 0 10px 15px;
    }

    li:first-child {
      margin-top: 10px;
    }

  }
  .personalize-header {
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 25px;
    .inline-color-wheel {
      top: 5px !important;
    }
    @media($screen-sm-max) {
      svg {
        position: relative !important;
      }
    }
  }
  .personalize-message {
    font-family: Helvetica, sans-serif;
    font-size: 15px;
    line-height: 18px;
    margin-top: 20px;
    margin-bottom: 20px;

    .fabric-content {
      margin-top: 1em;
      text-transform: capitalize;
    }
  }

  .product-footer {
    color: $dark-gray;
    font-family: $font-primary;
    font-size: 15px;
    line-height: 18px;
    margin-top: 15px;
  }

  .personalize-btn {
    margin: 0 30px 0 0;
    display: inline-block;
    z-index: 100;
    width: 100px;
    cursor: pointer;
    position: relative;
    .personalize-check {
      position: absolute;
      bottom: 8px;
      left: 8px;
      background-image: url('../../img/SVG/check-blank.svg');
      background-repeat: no-repeat;
      background-position: center;
      height: 30px;
      width: 30px;
      &.checked {
        background-image: url('../../img/SVG/check-filled.svg');
      }
    }
    .personalize-label {
      text-transform: uppercase;
      margin-bottom: 5px;
      display: block;
      font-size: 15px;
    }
    img {
      padding: 4px;
      border: 1px solid $dark-gray;
    }
  }
  .color-display-list {
    flex-wrap: wrap;
    display: flex;
    padding-left: 0;
    margin-bottom: 0 !important;
    margin-top: 20px !important;
    list-style: none;
  }

  .color-display-list li {
    padding: 2px;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }
  }

  .color-display-list li .item-link {
    padding: 10px;
    //background: #f2f2f2;
    position: relative;
    display: block;
  }

  .color-display-list li .item-link:before {
    display: none;
    content: "";
    position: absolute;
    background-color: #ffffff;
    right: -2px;
    left: -2px;
    bottom: -2px;
    top: -2px;
    z-index: -1;
    border-radius: 2px;
    border: 1px solid #cccccc;
  }

  .color-display-list li .item-link:hover:before, .color-display-list li .item-link.active:before {
    display: block;
  }

  .nts-pdp-color-swatches {
    width: calc(100% - 75px);
    display: inline-block;
    span {
      color: #111111;
      font-size: 14px;
      line-height: 18px;
      font-family: Helvetica, sans-serif;
      margin-bottom: 10px;
    }
    .color-label {
      font-weight: bold;
      margin-right: 5px;
    }
    .color-value {
      text-transform: uppercase;
    }
  }
  .nts-pdp-size-chart {
    width: 75px;
    display: inline-block;
    vertical-align: top;
    text-align: right;
    a {
      color: #000;
      text-decoration: underline;
      font-size: 16px;
      font-weight: normal;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      @media($screen-md-max) {
        font-size: 14px;
      }
    }
  }
  .pdp-large-image-col {
    margin-bottom: 20px;

    @media ($screen-md-min) {
      padding: 0 16% 0;
    }
    @media ($screen-sm-min) {
      .productImageDisplay {
        border: 1px solid $accent-gray;
      }
    }
  }
  .grey-divider {
    margin: 0 10px;
    color: #9B9B9B;
  }
  .pdp-action-btn-group {
    margin: 5px 0;
    margin-top: 25px;
    .pdp-cart-btn {
      width: 100%;
      margin: 5px 20px 5px 0;
      position: relative;
      padding: 0;
      @media ($screen-md-min) {
        width: 200px;
        display: inline-block;
        float: none;
      }
      &.disabled {
        opacity: 0.65;

      }
    }
    .pdp-cart-btn-trigger {
      display: inline-block;
      width: 100%;
      @media ($screen-md-min) {
        width: 200px;
      }
    }
  }
}

.pdp-share-option-row {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 30px;
  .share-label {
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
  }
  .share-icon {
    font-size: 33px;
    margin-right: 15px;
    color: $black;
    &:hover {
      color: $dark-gray;
      cursor: pointer;
    }
  }
  .share-icon-email {
    background-image: url('../../img/SVG/email-icon-hover.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    height: 30px;
    width: 30px;
    &:hover {
      background-image: url('../../img/SVG/email-icon.svg');
    }
  }
  .share-icon-print {
    background-image: url('../../img/SVG/print-button-hover.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    height: 30px;
    width: 30px;
    &:hover {
      background-image: url('../../img/SVG/print-button.svg');
    }
  }
  .share-icon-copy {
    background-image: url('../../img/SVG/link-button-hover.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    height: 30px;
    width: 30px;
    &:hover {
      background-image: url('../../img/SVG/link-button.svg');
    }
  }
  .share-input-copy {
    display: inline-block;
    vertical-align: top;
    max-width: 250px;
    height: 30px;
    padding: 5px;
  }

}

.artwork-modal {
  .modal-lg {
    width: 90%;
    height: 92%;
    margin-left: auto;
    margin-right: auto;
    @media($screen-sm-max) {
      width: 100%;
      height: 100%;
      margin: 0;
    }
    @media($screen-xl-min) {
      width: 75%;
    }
    .modal-content {
      height: 100%;
      padding: 20px;
      .modal-header {
        height: 108px;
        @media($screen-sm-max) {
          margin-top: -10px;
        }
        .modal-title {
          font-size: 24px;
          border-bottom: 1px solid $med-gray2;
          margin: 0 -20px 10px -20px;
          padding-bottom: 10px;
          @media($screen-sm-min) {
            font-size: 30px;
          }
        }
        @media($screen-sm-max) {
          .filter-col {
            display: none;
          }
        }
        &.filtering {
          @media($screen-sm-max) {
            height: 264px;
            .filter-col {
              display: block;
            }
          }
        }
        .artwork-filter-row {
          border-bottom: 1px solid $med-gray2;
          .artwork-search-box-container {
            .g72-search {
              display: none;
              @media($screen-sm-min) {
                display: inline-block;
                position: absolute;
                z-index: 1;
                right: 30px;
                top: 6px;
                font-size: 21px;
                color: $med-gray2;
              }
              &:hover {
                cursor: pointer;
              }
            }
            .artwork-search-box {
              position: relative;
              padding: 8px 30px 8px 8px;
              @media($screen-sm-max) {
                padding-right: 85px;
              }
            }
            .artwork-filter-toggle {
              font-family: $font-primary;
              position: absolute;
              right: 32px;
              top: 10px;
              font-size: 16px;
              border-left: 1px solid #ddd;
              padding-left: 10px;
              user-select: none;
              cursor: pointer;
              .g72-filter {
                margin-right: 5px;
              }
            }
          }
          .dropdown {
            width: 100%;
            .artwork-sport-filter {
              width: 100%;
              text-align: left;
              margin-bottom: 5px;
              .caret {
                float: right;
                top: 2px;
                position: relative;
              }

            }
            .dropdown-menu {
              width: 100%;
              margin-top: -5px;
            }
          }
          .artwork-filter-header {
            text-transform: uppercase;
            border-bottom: 1px solid $med-gray2;
            padding: 0 20px 5px 20px;
            margin: 0 -20px 10px -20px;
          }
          .artwork-filter-check {
            height: 18px;
            margin: 12px 0;
          }
        }
      }

      .artwork-item-list {

        .artwork-item-container {
          @media($screen-md-min) {
            float: left;
          }
          text-align: center;
          font-size: 13px;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          margin: 0 auto;
          width: 227px;
          display: block;
          padding: 10px;

          .artwork-img-container {
            display: block;
            height: 190px;
            margin: 0 auto;
            position: relative;

            img {
              vertical-align: middle;
              border: 0;
              height: 100%;
              padding: 5px;
            }

            .artwork-info-icon {
              font-size: 24px;
              position: absolute;
              top: 5px;
              right: 5px;
              color: #333;
              border: 1px solid #fff;
              border-radius: 12px;
              width: 21px;
              height: 20px;
              line-height: 19px;
              background-color: #fff;
              padding-left: 0.5px;
              cursor: pointer;

              &:hover {
                color: #6d6d6d;
              }

            }
            .artwork-color-details {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background-color: rgba(255, 255, 255, 0.85);
              border: 1px solid $med-gray;

              .artwork-no-color-msg {
                margin-top: 80px;
                font-weight: bold;
                font-size: 12px;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
              }

              ul {
                list-style: none;
                margin-top: 40px;
                text-align: left;

                li {


                  .nts-color-swatch {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    border: 1px solid #333;
                    border-radius: 7px;
                    cursor: pointer;
                    overflow: hidden;
                    margin-right: 10px;
                    position: relative;
                  }

                  .nts-color-label {
                    display: inline;
                    vertical-align: top;
                    font-weight: bold;
                    font-size: 10px;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                  }
                }

              }
              .g72-x-small {
                font-size: 24px;
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 5px;
              }
            }
          }
          .artwork-details-container {
            margin-top: 10px;
          }
        }

      }

      .artwork-icon-container {
        background-color: white;
        display: inline-block;
        padding: 5px;
        height: 290px;
        width: 230px;
        overflow: hidden;
        cursor: pointer;
        font-size: 13px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        @media($screen-sm-max) {
          width: 33.3333%;
        }

        img {
          height: 190px;
          margin-bottom: 10px;
        }
      }
      .artwork-load-more-container {
        padding: 50px 0;
        text-align: center;
        clear: both;
      }
      .artwork-no-results {
        margin: 80px 20px;
        color: $dark-gray;
        text-transform: none;
        font-family: $font-primary;
        h2 {
          text-transform: none;
          font-size: 16px;
          font-weight: bold;
        }
        p {
          font-size: 14px;
          line-height: 28px;
          margin: 0 auto;
          max-width: 300px;
          text-align: center;
        }
        .btn-container {
          width: 100%;
          text-align: center;
          margin: 20px 0;
        }
        .link {
          color: $dark-gray;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
    .modal-footer {
      .nts-artwork-footer {
        display: block;
        width: 100%;
        margin-top: 10px;
        span {
          float: left;
          font-size: 16px;
          text-align: left;
          width: 85%;
        }
        @media($screen-sm-max) {
          text-align: center;
          margin-top: 20px;
          span {
            line-height: normal;
            text-align: center;
            margin-bottom: 20px;
            width: 100%;
          }
        }
      }
    }
  }

}

.builder-modal {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  margin: 0;
  .modal-content {
    height: 100%;
    padding: 30px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    .modal-header {
      @media($screen-sm-max) {
        margin: 0 -20px;
        border-bottom: 2px solid $light-gray2;
      }
      .builder-modal-title {
        font-size: 30px;
        font-family: $font-secondary;
        text-align: left;
        line-height: 1;
        @media($screen-sm-max) {
          font-size: 24px;
          text-align: center;
          padding: 0;
        }
        .builder-modal-terms-link {
          float: right;
          font-family: $font-primary;
          font-size: 12px;
          color: #666;
          text-transform: none;
          text-decoration: underline;
          letter-spacing: normal;
          word-spacing: normal;
          @media($screen-sm-max) {
            display: none;
          }
        }
      }
    }

    .builder-modal-body {
      width: 100%;
      height: calc(100% - 50px);
      overflow: visible;
      padding: 0px;
      #tabs {
        overflow: visible;
        .builder-modal-nav-container {
          overflow: hidden;
          height: 40px;

          .nav-tabs {
            overflow: hidden;
            @media($screen-sm-max) {
              margin: 0;
              overflow-x: scroll;
              padding-bottom: 17px;
              height: 57px;
            }
            li,
            li.layoutTab {
              a,
              a:hover,
              a:active,
              a:focus {
                color: $black;
                background-color: white;
                span {
                  color: $black;
                  background-color: white;
                  white-space: nowrap;
                  overflow: hidden;
                  text-transform: uppercase;
                  @media($screen-sm-max) {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        div[class^="nts-logo-preview-container-"] {
          height: 150px;
          width: fit-content;
          max-width: 300px;
          text-align: center;
          margin: 0 auto;

          .nts-logo-preview-label {
            display: block;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 11px;
            text-align: center;
            text-transform: uppercase;
            word-break: break-word;
          }
          p.nts-logo-preview-label {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0;
          }
        }
        .nts-logo-preview-container-1 {
          margin-top: 150px !important;
        }
      }

      .main-content-row {
        height: 280px;
        @media($screen-sm-min) {
          height: calc(100% - 40px);
        }
        .main-content-col {
          @media($screen-sm-max) {
            padding: 0;
          }
        }
        .tab-content-container {
          top: 60px;
          right: 0px;
          left: 0px;
          bottom: 20px;
          position: absolute;
          overflow: hidden;
          @media($screen-sm-max) {
            bottom: 0;
          }
          .tab-pane {
            height: 100%;
            overflow: hidden;
            .carousel {
              overflow-y: hidden;
              padding-bottom: 20px;
              @media($screen-sm-max) {
                &.nts-artwork-carousel .carousel-inner {
                  height: 200px;
                }
              }
              .carousel-inner {
                width: calc(100% - 100px);
                margin: 0 auto;
                height: 80px;
                @media($screen-sm-min) {
                  width: 100%;
                  height: 100%;
                }
                .item {
                  @media($screen-sm-min) {
                    display: block;
                    margin-bottom: 20px;
                  }
                }
              }
              .carousel-control {
                width: 15px;
                color: $dark-gray;
                -webkit-box-shadow: none;
                box-shadow: none;
                text-shadow: none;
                font-size: 38px;
                top: 20px;
                @media($screen-sm-min) {
                  display: none;
                }
                &.left {
                  left: 20px;
                }
                &.right {
                  right: 20px;
                }
              }
              .carousel-indicators {
                bottom: 2px;
                display: inline;
                width: auto;
                transform: translateX(-50%);
                @media($screen-sm-min) {
                  display: none;
                }
                LI {
                  background-color: $med-gray2;
                  border: none;
                  margin: 1px 5px;
                  &.active {
                    background-color: $black;
                    width: 10px;
                    height: 10px;
                    margin: 1px 5px;
                  }
                }
              }
            }

            .nts-logo-pending {
              background-image: url('../../img/SVG/pending_watermark.svg');
              background-repeat: no-repeat;
              background-position: center;
            }

            .artwork-panel {
              padding-bottom: 30px;

              @media($screen-sm-max) { padding: 0 15px; }

              .artwork-upload-optout {
                margin-bottom: 40px;
                padding-top: 1px;
                padding-left: 2px;
                .artwork-upload-msg {
                  color: $med-gray;
                  font-family: $font-primary;
                }
                .artwork-terms-link {
                  display: none;
                  @media($screen-sm-max) {
                    display: inline-block;
                    float: right;
                    color: #666;
                    font-size: 11px;
                    font-family: $font-primary;
                    text-decoration: underline;
                  }
                }
                @media($screen-sm-max) {
                  margin-bottom: 10px;
                  .artwork-upload-msg {
                    font-size: 10px;
                  }
                }

              }

              .artwork-upload-container {
                padding-left: 2px;
                max-width: 345px;

                .print-type-btn {
                  margin-bottom: 40px;
                  @media($screen-sm-max) { margin-bottom: 10px; }
                }

                .selected-artwork-name {
                  height: 40px;
                  line-height: 40px;
                  width: calc(100% - 98px);
                  border: 1px solid $med-gray2;
                  padding-left: 12px;
                  font-family: $font-primary;
                  font-size: 15px;
                  display: inline-block;
                  overflow: hidden;
                }

                .nts-builder-logo-cta {
                  button {
                    padding: 0 16px;
                    margin: 5px 0;
                  }
                  button:first-of-type {
                    margin-right: 20px;
                  }
                  @media(max-width: 1170px) {
                    button {
                      width: 100%;
                    }
                    button:first-of-type {
                      margin-right: 0;

                    }
                  }
                  @media($screen-sm-max) {
                    button {
                      padding: 8px;
                      font-size: 12px;
                      height: auto;
                      width: 48%;
                    }
                    button:first-of-type {
                      margin-right: 4%;
                    }
                  }
                }

              }

              @media($screen-sm-max) {
                .control-label,
                .nts-logo-dimensions {
                  display: inline-block;
                  width: 50%;
                }
              }


              .nts-logo-dimensions {
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 11px;
              }

            }

            .options-panel {
              position: absolute;
              width: 100%;
              top: 0px;
              bottom: 0;
              background: rgb(255, 255, 255);
              @media($screen-sm-max) {
                position: fixed;
                left: 0;
                right: 0;
                z-index: 1000;
              }
              .options-panel-header {
                font-family: $font-secondary;
                text-transform: uppercase;
                padding-bottom: 10px;

                @media($screen-sm-max) {
                  text-align: center;
                  font-size: 20px;
                  padding-top: 10px;
                  border-bottom: 1px solid $light-gray2;
                }
                .g72-x-small {
                  line-height: 22px;
                  font-size: 22px;
                  position: absolute;
                  right: 0;
                  top: 0;
                  @media($screen-sm-max) {
                    right: 10px;
                    top: 9px;
                  }
                  cursor: pointer;
                  &:hover {
                    color: $dark-gray;
                  }
                }
              }

              .generic-swatch-option {
                height: 39px;
                width: 100%;
                background-color: $light-gray;
                border: 1px solid $light-gray;
                margin-bottom: 5px;
                cursor: pointer;
                display: table;
                &.selected {
                  background-color: $accent-gray;
                }
                &:hover {
                  border: 1px solid $med-gray;
                }

              }
            }
          }

        }
      }
      .mobile-template-row {
        .mobile-template-col {
          DIV:first-of-type {
            max-width: 375px;
            padding: 0 !important;
            text-align: center;
          }
        }
      }
    }
    .builder-modal-buttton-row {
      @media($screen-sm-max) {
        /*position: absolute;
        bottom: 0;
        left: 0;
        right: 0;*/
        padding-top: 10px;
        .col-xs-12 {
          padding: 0 10px;
        }
      }
      .builder-modal-btn {
        width: auto;
        margin-left: 10px;
        @media($screen-sm-min) {
          width: 130px;
        }
        @media($screen-lg-min) {
          width: 190px;
        }
      }
    }

    .layout-thumb-container {
      width: 100%;
      height: 150px;

      > DIV:first-of-type {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
      }
      @media($screen-sm-min) {
        height: 100%;
        > DIV:first-of-type {
          overflow-x: hidden;
          overflow-y: scroll;
          white-space: normal;
        }

      }
      .graphic-thumb {
        padding: 10px;
        cursor: pointer;
        position: relative;
        border: 2px solid $light-gray;
        background-color: $light-gray;
        width: 120px;
        height: 120px;
        display: inline-block;
        margin: 0 14px 44px 0;

        &.selected {
          border: 2px solid $dark-gray;
        }
        &.unpublished {
          border: 2px solid $red;
        }
        .graphic-thumb-name {
          color: $black;
          font-family: $font-primary;
          font-size: 11px;
          line-height: 12px;
          padding: 6px 0;
          text-align: center;
          width: 100%;
          position: absolute;
          top: 102%;
          left: 0;
          white-space: normal;
          word-break: break-all;
        }
      }
    }
    input[type='text'] {
      font-size: 16px;
      width: 100%;
      padding: 10px 8px 6px 8px;
      border: 1px solid $med-gray2;
      box-shadow: none;
      box-sizing: border-box;
      color: $black;
      margin-bottom: 0;
    }
    .color-swatch {
      padding-top: 33.33%;
      cursor: pointer;
      border-top: 1px solid $accent-gray;
      border-right: 1px solid $accent-gray;
      width: 33.33%;
      background-color: $light-gray;
      position: relative;
      float: left;
      box-sizing: border-box;
      &:hover {
        background-color: $accent-gray;
      }
      &:nth-of-type(3n+0) {
        border-right: none;
      }
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        border-top: none;
      }
      .color-dot {
        width: 50%;
        height: 50%;
        background-color: gray;
        border-radius: 50%;
        transform: translateY(-15%);
        transform: translateX(-50%);
        position: absolute;
        top: 15%;
        left: 50%;
      }
      .color-name {
        position: absolute;
        top: 64%;
        bottom: 0%;
        width: 100%;
        text-align: center;
        color: $black;
        font-family: $font-primary;
        font-size: 11px;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .option-button {
      height: 40px;
      border: 1px solid $med-gray2;
      cursor: pointer;
      font-family: $font-primary;
      font-size: 16px;
      text-transform: initial;
      position: relative;
      width: 100%;
      max-width: 400px;
      display: grid;
      grid-template-columns: 1fr auto;

      .color-dot {
        width: 20px;
        height: 20px;
        background-color: gray;
        border-radius: 50%;
        position: relative;
      }
      .option-contents {
        width: calc(100% - 37px);
        white-space: nowrap;
        overflow: hidden;
        @media($screen-sm-max) {
          font-size: 14px;
        }
      }
      .option-carrot {
        color: $dark-gray;
        line-height: 35px;
        font-size: 16px;
        margin-right: 10px;
        margin-top: 2px;
      }
      .option-locked {
        color: $med-gray2;
        line-height: 35px;
        font-size: 16px;
        margin-right: 10px;
        margin-top: 2px;
      }

      &.selected {
        background-color: $light-gray;
      }
      &:hover {
        background-color: $light-gray;
        .option-carrot {
          color: $black;
        }
      }

      .print-type-picker-content,
      .font-picker-content {
        padding: 11px 8px 11px 8px;
        font-family: $font-primary;
        text-transform: initial;
      }

      &.disabled {
        cursor: default;
        &:hover {
          background-color: $white;
          cursor: default;
        }
      }
    }
  }
  .layout-info {
    .layout-info-header {
      font-family: $font-secondary;
      font-size: 14px;
      text-transform: uppercase;
    }
    UL {
      padding: 10px 20px;
      LI {
        list-style-type: disc;
        margin: 5px 0;
      }
    }
    .link {
      color: blue;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

/*****************************/
/***** DEALER CART MODAL *****/
/*****************************/
.dlr-cart-modal {
  .modal-dialog {
    box-sizing: border-box;
    font-size: 0.75em;
    font-family: "HelveticaNeue", Helvetica, sans-serif;

    @media ($screen-sm-max) {
      margin: 50px 0 0 0;
    }
    .modal-content {
      border-radius: 2px;
      @media ($screen-sm-max) {
        border-radius: 0;
      }
      button.close {
        z-index: 1;
      }

      hr {
        margin: 0.5rem;
      }

      .modal-body {
        padding: 5px 15px;
        overflow: hidden;

        .dlr-cart-col {
          padding: 0 5px;
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }
        }
      }

      .modal-footer {
        border: none;
      }

      .dlr-cart-hdr-options {
        input {
          height: 100%;
          display: inline-block;
          font-size: 15px;
          background-position: right -45px top -1844px;
          border-color: #DDD;
          border-radius: 0;
          padding-top: 7px;

          &.search {
            position: relative;
            padding-left: 32px;
            padding-top: 7px;
          }
        }

        .searchImage {
          position: absolute;
          left: 15px;
          top: 13px;
          font-size: 15px;
          font-weight: bold;
          content: url('../../img/SVG/search-icon.svg');
        }

      }

      .dlr-cart-msg-display {
        margin-bottom: 10px;

        .error {
          color: $error-red;
        }
        .success {
          color: green;
        }

      }

      .dlr-cart-order-list {
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 15px;
        width: 100%;

        .dlr-cart-order-line {

          margin: 0 0 3px 0;
          padding: 6px;
          height: 40px;
          /*line-height: 40px;*/
          background-color: #F7F7F7;
          border: 1px solid #F9F9FA;
          font-weight: bold;
          font-family: TradeGothicCondensed, TradeGothic, sans-serif;

          span {
            margin-left: 10px;
            text-transform: uppercase;
            font-size: 12px;
            display: inline-block;
            position: relative;
            top: 3px;
            &.disabled {
              color: $med-gray2;
            }
          }

          &:hover, &:active {
            cursor: pointer;
            background-color: #DDD;
            border: 1px solid #DDD;
          }

          input {
            display: inline-block;
            margin-top: 2px;
            &:after {
              top: 5px;
            }
          }

        }

      }
    }

  }

}


.nts-terms-modal {
  overflow: auto;

  .modal-dialog {
    .modal-content {

      color: #000;
      font-family: TradeGothicCondensed, TradeGothic, Helvetica, Arial, sans-serif;
      text-align: center;

      .modal-header {
        h4.modal-title {
          margin: 0;
        }
        p {
          margin-top: 45px;
          margin-bottom: 0;
        }
      }

      .modal-body {
        label {
          font-weight: bold;
          font-size: 15px;
          font-family: $font-primary;
          margin-top: 5px;
        }
        p {
          font-size: 16px;
          margin: 10px 20px 20px 20px;
        }
        p.terms-text {
          font-family: $font-primary;
          font-size: 14px;
          line-height: 24px;
          max-width: 900px;
        }
      }
      .modal-footer {
        border: none;
        text-align: center;
        .container-modal-artwork-agree-terms {
          width: 100%;
          max-width: 330px;
          margin: 0 auto 15px auto;
        }
      }
    }
  }
}
/*
.container-modal-artwork-center {
  text-align: center;
  display: block;
  strong {
    font-style: italic;
  }
  input {
    margin: 0;
  }
}
div.container-modal-artwork-center {
  padding-top: 2%;
}
.container-modal-artwork-agree-terms {
  text-align: center;
  display: block;
  margin: auto !important;
  width: 30%;
}
*/

.nts-artwork-error-modal {
  margin-top: 15%;
  width: 100%;
  max-width: 390px;
  height: 50%;
  max-height: 250px;

  h4 {
    padding-top: 25%;
  }

  button span {
    color: #666666;
  }

  .modal-body {

    padding-top: 0;
    font-family: $font-primary;

    div {
      text-align: center;
      display: inline-block;
    }

    span {
      width: 100%;
      display: inherit;
    }
  }

  div {
    margin: auto !important;
  }

  .modal-footer {
    border-top: 0;
    width: 95%;

    div {
      width: inherit;
    }
  }
}
.nts-artwork-image-selector-modal-container {

  height: 100%;
  overflow: auto;

  .nts-artwork-image-selector-modal {

    width: 600px;
    margin-top: 140px;
    margin-bottom: 20px;

    @media($screen-sm-max) {
      width: 100%;
      max-width: 90% !important;
      margin: 40px auto 20px auto;
    }

    .row {
      margin: auto;
    }

    .modal-header {
      h4 {
        margin-top: 40px !important;
      }

      button.close {
        top: 0;
        font-size: 20px;
      }
    }

    .modal-body {
      padding-top: 0;

      @media($screen-sm-max) {
        padding: 0 0 15px 0;

        .preview-canvas {
          max-width: 125px !important;
          max-height: 125px !important;
          background-size: 75px !important;
        }
      }

    }

    .image-color-upper-body {
      width: 70%;
      margin: 10px auto 25px auto;
      font-family: Helvetica, Arial, sans-serif;

      @media($screen-sm-max) {
        width: 100%;
        margin: 0 0 20px 0;
        padding: 0;
      }

      span {
        text-align: center;
        display: block;

        @media($screen-sm-max) {
          font-size: smaller;
        }
      }
    }

    .center-content {
      text-align: center;
      display: grid;

      img {
        margin-left: 20%;
        height: 150px;
        max-width: 180px;
      }
    }

    .actions {
      display: contents;

      @media($screen-sm-max) {
        width: 100%;
        display: grid;
      }

      .left-side {
        margin-right: 10px;
        margin-left: 10%;

        @media($screen-sm-max) {
          width: inherit;
          margin: 0;
        }
      }

      .right-side {
        margin-left: 10px;
        margin-right: 10%;

        @media($screen-sm-max) {
          width: inherit;
          margin: 10px 0;
        }
      }

    }

    .bg-img {
      background-image: url('../../img/SVG/checkered_bg.svg') !important;
      background-repeat: repeat  !important;
      background-position: center  !important;
    }

    .left-side {
      .logo-name {
        margin-bottom: 5%;
      }

      .file-name {
        margin-top: 5%;
      }
    }

    .grid-filter-color-options {
      margin-top: 5%;
      margin-left: 10%;
    }

    .left-side, .right-side {
      width: 50%;
      display: inline-block;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      vertical-align: top;
    }

    .left-side {
      border-right: 1px solid #eceeef;
    }

    .right-side {
    }

    .modal-footer {
      border: none;
    }
  }

}


.artwork-upload-login-btn-group {
  .artwork-upload-login-txt {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 0 20px;
  }
  .artwork-upload-login-btn {
    width: 170px;
    margin: 5px 0 5px 20px;
    position: relative;
    padding: 0;
    @media ($screen-md-min) {
      display: inline-block;
      float: none;
    }
  }
}

.sizes-container {
  margin-top: 25px;
  .hide-show-label {
    display: block;
    cursor: pointer;
    p {
      margin-bottom: 0 !important;
      font-size: 16px;
      font-weight: normal;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
  .sizes-list-container {
    margin: 20px 0 0;
    min-height: 120px;
    position: relative;
    .sizes-list {
      list-style: outside none none;
      padding: 0;
      margin: 0;
      width: 100%;
      display: inline-block;

      li {

        float: left;
        .size-holder {
          .size-name {
            text-align: center;
            color: #FFF;
            border: none;
            padding: 8px 12px;
            background-color: #000;
            min-width: 60px;
          }
          .size-quantity {
            color: #228B22;
            border:1px solid #EEE;
            padding: 8px 12px;
            min-width: 60px;
            text-align: center;
          }
        }
        &:first-child{
          .size-quantity{
            border-left-width: 2px;
          }
        }
        &:last-child{
          .size-quantity{
            border-right-width: 2px;
          }
        }
        &.disabled{
          .size-holder{
            .size-name{
              background-color: #ccc;
            }
          }
        }
      }
    }
  }

}

.nts-logo-canvas-container {
  position: relative;
  width: fit-content;
  margin: auto;
}

.nts-logo-refresh {

  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  height: 30px;
  width: 30px;

  background-image: url('../../img/SVG/refresh-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;

  &:hover {
    opacity: 0.75;
  }

}

.artwork-email-modal {
  @media ($screen-md-min) {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .modal-dialog {
    @media ($screen-sm-max) {
      margin: 0;
    }
  }

  .modal-title {
    font-size: 32px;
  }
  .modal-body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    li::marker {
      font-weight: bold;
    }
  }
  .modal-footer {
    border-top: 0;
  }
}

.email-share-modal {
  .form-control {
    padding: 10px;
  }
  input.error, textarea.error {
    border-color: $error-red;
  }
}
