.nts-past-orders-container {
  font-family: TradeGothicCondensed, TradeGothic, "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
  box-sizing: border-box;
  padding-top: 25px;
  min-height: calc(100vh - 295px);

  @media($screen-sm-max) {
    padding: 25px 0 0 0;
  }

  .nts-past-orders-header {
    margin: 20px 0;
    h1 {
      margin: 10px 0 !important;
    }
  }

  .nts-table-wrapper {
    margin: 25px 0;
  }

  .nts-table {
    height: calc(100vh - 250px);
  }

  .past-orders-cart-tab {
    display: inline-block;
    width: auto;
    //height: 100%;
    text-align: center;
    color: $med-gray;
    font-size: 20px;
    margin-right: 20px;

    &.active {
      border-bottom: 2px solid #000;
      color: #000;
    }

    &:hover {
      cursor: pointer;
      color: #000;
      border-bottom: 2px solid #000;
    }
  }

  .past-orders-cont-shop {
    float: right;
    margin: 10px 0;
    span {
      padding-right: 10px;
    }
  }

  .past-order-btn-caret {
    -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg);        /* FF */
    -o-transform: rotate(180deg);          /* Opera */
    -ms-transform: rotate(180deg);         /* IE9 */
    transform: rotate(180deg);
    color: $med-gray;
    /*line-height: 25px;*/
    margin-right: 10px;
    margin-top: -2px;
    height: 15px;
  }

  .past-order-search {
    display: inline-block;
    font-size: 15px;
    background-position: right -45px top -1844px;
    border: 1px solid #ddd;
    border-radius: 2px;
    position: relative;
    padding: 4px 6px 4px 30px;
    float: right;
    height: 35px;
    margin-bottom: 5px;
    font-family: "TradeGothicCondensed", "TradeGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: #ddd;
  }

  .past-order-search-icon {
    position: absolute;
    left: 18px;
    top: 7px;
    font-size: 22px;
    font-weight: bold;
    color: $med-gray !important;
    &:hover {
      cursor: pointer;
    }
  }

  .past-order-pagination {
    float: right;
    font-size: 15px;

    span {
      padding-left: 4px;
      color: $nike-orange;

      &:hover {
        cursor: pointer;
        color: $med-gray;
      }
    }

    span.active {
      color: #000;
    }
  }

  .past-orders-table {
    width: 100%;

    tr > th, .rt-th {
      border: 0 0 2px 0 solid $med-gray;
      padding: 10px 0;
      font-weight: normal;
      font-size: 15px;

      span {
        display: inline-block;
      }
    }

    tr > td {
      border-bottom: 1px solid $med-gray;
      padding: 10px 0;
      color: $dark-gray;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .glyphicon-chevron-down {
      color: $med-gray;
      margin-left: 5px;
      font-size: 12px;

      &.active {
        color: $nike-orange;
      }

      &:hover {
        color: #000;
        cursor: pointer;
      }

    }

  }

  .nts-table-tracking-nbr {
    display: block;
    padding-left: 3px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    &:hover {
      background-color: $med-gray;
      color: #FFF;
      font-weight: bold;
    }
  }

}