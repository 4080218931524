.nts-admin-reporting-page {

  .admin-tabs-content {
    padding: 20px !important;

    .nts-table {
      .rt-tbody {
        .rt-tr-group {
          .rt-tr {
            cursor: default;
            .rt-td {
              .icon {
                margin-right: 20px;
              }
              .email-icon {
                &:hover {
                  cursor: pointer;
                }
                position: relative;
                height: 25px;
                width: 25px;
                filter: invert(0%) sepia(2%) saturate(19%) hue-rotate(356deg) brightness(85%) contrast(100%);
                content: url('../../../../img/SVG/email-icon.svg');
              }
              .download-icon {
                &:hover {
                  cursor: pointer;
                }
                height: 25px;
                width: 25px;
                content: url('../../../../img/download_icon.png');
              }
              .impersonate-icon {
                &:hover {
                  cursor: pointer;
                }
                height: 25px;
                width: 25px;
                content: url('../../../../img/user_impersonate_icon_no_background.png');
              }
              .dropdown-toggle .btn .btn-link {
                height: auto;
              }
              .user-impersonate-dropdown-wrapper {
                height: 28px;
                button {
                  height: auto;
                }
                .user-impersonate-icon-no-background::before {
                  position: absolute;
                  height: 25px;
                  width: 25px;
                  top: -2px;
                  left: calc(50% - 32px);
                  content: '';
                  background: url('../../../../img/user_impersonate_icon_no_background.png') no-repeat;
                }
                .user-impersonate-dropdown-menu {
                  padding: 0;
                  left: -130px;
                  .user-impersonate-dropdown-option > a {
                    color: $med-gray;
                    &:hover {
                      color: $nike-orange;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .error {
      color: red;
      margin: 5px 0 15px 0;
    }

    .nts-tab-search-bar > label > input {
      width: 270px !important;
    }

  }


}