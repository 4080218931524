.nts-admin-analytics-page {

  .nts-analytics-search-bar {
    padding: 20px 0;

    label {
      font-weight: normal;

      span {
        margin-right: 10px;
      }

      input {
        margin-right: 20px;
        border: 1px solid #ddd;
        border-radius: 0;
        height: 30px;
      }

      select {
        margin-right: 20px;
        border: 1px solid #ddd;
        border-radius: 0;
        height: 30px;
      }

    }

    button {
      height: 30px;
      border-radius: 0;
      line-height: 30px;
      margin-top: -4px;
    }

  }

  .nts-analytics-search-results {

    table {

      thead {
        text-transform: uppercase;
        background-color: $black;
        color: $white;
      }

      tbody {
        td {
          width: 33.33%;
          word-break: break-all;
        }
      }

    }
  }

  .error {
    color: red;
    margin: 5px 0 15px 0;
  }

}
.nts-style-tabs{
  padding: 0 !important;
  margin-bottom: 10px;
  .nts-styles-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 20px;
      margin-bottom: 0px !important;
    }
  }
 
}