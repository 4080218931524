.nts-coupon-modal {
  text-align: center;

  .modal-dialog {
    max-width: 400px;
    margin-top: 25vh;
    margin-left: auto;
    margin-right: auto;

    .modal-header {

      .nts-modal-header {
        font-size: 30px;
        margin-top: 30px;
      }

      button.close {
        font-size: 20px;
      }

    }

    .modal-body {
      padding: 25px;

      input.nts-coupon-input {
        @extend .nts-input;
        min-width: 200px;
        min-height: 40px;
        width: 100%;
        display: inline-block;
        font-size: 14px !important;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px !important;

        &.error {
          border-color: $error-red;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
      }

      button {
        display: inline-block;
        width: 100%;
      }

      .nts-coupon-error {
        color: $error-red;
        font-size: 14px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        margin-bottom: 5px;
      }

      .nts-coupon-lookup-entry {

        .nts-modal-subheader {
          margin-bottom: 30px;
        }

      }

      .nts-coupon-lookup-details {
        text-align: left;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        p.nts-coupon-detail {
          margin: 0;
        }
      }

    }
  }

  .nts-modal-subheader {
    font-size: 14px;
    color: $med-gray;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

}

.nts-coupon-bar {
  width: 100%;
  background-color: #22242b;
  display: block;
  clear: both;
  color: $white;
  padding: 11px 20px 10px 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 26px;
  height: 50px;
  position: relative;
  font-size: 14px;

  span {
    vertical-align: middle;
  }

  .nts-coupon-bar-code {
    font-family: TradeGothicCondensed, TradeGothic, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .nts-coupon-bar-expire {
    color: $med-gray;
  }

  .nts-coupon-bar-error {
    color: $error-red;
    font-weight: bold;
    display: inline-block;
    line-height: 14px; //MOBILE ONLY?
  }

  .nts-coupon-bar-balance {
    span {
      color: green;
      font-weight: bold;
    }
  }

  .nts-coupon-bar-info {
    margin-right: 5px;
    font-size: 20px;
    color: #FFF;
    font-weight: 100;
    cursor: pointer;
    display: inline-block;
    line-height: 20px;

  }

  .nts-coupon-bar-remove-link {
    float: right;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .nts-coupon-bar-remove-x {
    display: none;
  }

  .nts-coupon-label-spacer {
    margin-right: 20px;
  }

  @media($screen-sm-max) {
    height: 72px;
    text-align: center;

    .nts-coupon-bar-code {
      display: block;
    }

    .nts-coupon-bar-remove-x {
      display: block;
      position: absolute;
      top: 5px;
      right: 10px;
      font-size: 18px;
    }

    .xs-hide {
      display: none;
    }

  }

  @media ($screen-sm-min) and (max-width: 879px) {
    .nts-coupon-bar-error {
      max-width: 380px;
    }
    .nts-coupon-bar-remove-x {
      display: block;
      position: absolute;
      top: 5px;
      right: 10px;
      font-size: 18px;
    }
    .nts-coupon-bar-remove-link {
      display: none;
    }
  }

  @media (min-width: 880px) and ($screen-md-max) {
    .nts-coupon-bar-error {
      max-width: 360px;
    }
  }

}

/*Tooltip overrides for negative balance info*/
#tooltip {
  font-size: 100px;
  opacity: 0.95;
  .tooltip-arrow {
    background-color: #22242b !important;
    opacity: 0.5;
  }
  .tooltip-inner {
    text-align: left;
    max-width: 250px;
  }
}
