.nts-user-profile-page {
  min-height: calc(100vh - 295px);
  padding-top: 40px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;

  h2 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .nts-profile-mobile-panel-close {
    display: none;
    z-index: 1;

    i { font-size: 26px; }

    @media($screen-md-max) {
      display: inline-block;
      position: absolute;
      top: 90px;
      right: 30px;
    }

  }

  .nts-profile-nav-option {
    height: 44px;
    line-height: 44px;
    cursor: pointer;
    color: $med-gray;
    box-shadow: inset 0 -1px 0 0 $accent-gray;

    @media($screen-md-max) {
      color: $black;
    }

    &.active,
    &:hover,
    &:active {
      color: $black;
    }

    .nts-profile-nav-icon {
      width: 32px;

      &.g72-gear {
        font-size: 30px;
        line-height: 44px;
        font-weight: bold;
      }

      &.g72-email {
        font-size: 18px;
      }

    }

  }

  .nts-profile-req-container {
    margin: 10px 0 20px 0;
    padding-top: 40px;
    box-shadow: inset 0 1px 0 0 $accent-gray;
  }

  .nts-profile-notify-toast {
    max-width: 768px;
    background-color: $light-gray;
    padding: 10px;
    margin-bottom: -15px;

    i {
      font-size: 20px;
      line-height: 20px;
      margin-right: 5px;
    }

    i.cancel { color: $black }
    i.success { color: $light-green }
    i.error { color: $error-red }

    -webkit-animation: fade-out 6s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    animation: fade-out 6s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
  }

  .nts-profile-notifications-panel {

    p { margin-bottom: 20px; }
    h2 { margin-bottom: 40px; }
    h4 { margin-bottom: 20px; }

    h3 {
      font-size: 20px;
      margin-bottom: 12px;
    }

    .nts-profile-well-container {
      background-color: $light-gray;
      padding: 20px;
      margin-bottom: 16px;

      a {
        color: $black;
        text-decoration: underline;
        padding-left: 4px;
      }

      .g72-chat {
        font-size: 20px;
      }

      .nts-profile-notification-container {
        width: 80%;
        display: inline-block;
      }
      .nts-profile-check-container,
      .nts-profile-icon-container {
        width: 10%;
        margin: auto;
        display: inline-block;
        vertical-align: top;
        text-align: center;
      }
      .nts-profile-check-container {
        input::after {
          background-color: white;
        }
      }

    }

  }

  .nts-profile-cta-container {
    max-width: 768px;
    margin-top: 20px;
    text-align: right;
    padding: 40px 0;
    box-shadow: inset 0 -1px 0 0 $accent-gray, inset 0 1px 0 0 $accent-gray;

    button {
      margin-left: 20px;
      min-width: 100px;
    }
  }

  .nts-profile-faux-input {
    height: 60px;
    line-height: 60px;
    background-color: #FAFAFA;
    padding: 0 10px;

    .faux-value {
      display: inline-block;
      width: 70%;
    }

    .faux-cta {
      display: inline-block;
      width: 30%;
      text-align: right;
      button {
        margin: 0;
      }
    }

  }

}

.nts-profile-form {
  max-width: 768px;

  .required {
    color: $error-red;
    display: inline;
  }

  .nts-basic-form-section {
    width: 100%;
    input,
    label {
      width: 100%;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
    input.error {
      border: 1px solid $error-red;
    }
    label {
      margin: 0 0 30px 0;
    }
    .nts-basic-form-error {
      color: $error-red;
      padding: 5px 0;
    }

  }
}
.nts-style{
  display:flex;
  align-items: center;
}
.nts-style-search-btn{
  margin-top: 5px !important;
}

.nts-profile-modal {

  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow: auto;

  &.pass-modal { .modal-dialog { margin-top: 20vh; } }

  .modal-dialog {
    max-width: 460px;
    margin-top: 25vh;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;

    .modal-content {
      padding: 48px;

      .modal-header {
        h1 {
          font-size: 24px;
          font-weight: 500;
          font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
          line-height: 1.5;
        }
      }
      .modal-body {
        padding: 20px 0;

        p {
          font-size: 16px;
          margin: 0;
        }

        .nts-profile-pass-validation {
          margin-bottom: 20px;

          .nts-profile-pass-req {
            p { line-height: 28px; }

            .validation-check {
              width: 20px;
              margin-right: 4px;
              display: inline-block;
              .g72-check {
                font-size: 16px;
                display: none;
                &.passed {
                  color: #3ca023;
                  display: inline-block;
                }
              }
            }

            .validation-msg {
              color: $med-gray;
              display: inline-block;
              &.passed {
                color: $black;
              }
            }

          }

        }

        .nts-profile-notify-toast {
          max-width: 768px;
          background-color: $light-gray;
          padding: 10px;
          margin-bottom: 25px;

          i {
            font-size: 20px;
            line-height: 20px;
            margin-right: 5px;
          }

          i.cancel { color: $black }
          i.success { color: $light-green }
          i.error { color: $error-red }

          -webkit-animation: fade-out 6s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
          animation: fade-out 6s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        }

        .nts-profile-pass-btn-container {
          margin-top: 20px;
          button {
            margin-left: 20px;
            min-width: 100px;
          }
        }

      }
      .modal-footer {
        margin-top: 16px;
        padding: 40px 0 0 0;
        display: block;
        button {
          float: left;
          margin-right: 20px;
        }
      }

    }

  }

}
