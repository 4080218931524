.nts-fill-in-order-page {
  min-height: calc(100vh - 295px);

  .header {
    padding-top: 50px;
    padding-bottom: 10px;
    font-size: 36px;
  }

  .input-area {
    width: 300px;
    float: left;
  }

  .submit-button {
    @media($screen-sm-min) {
      margin-left: 20px;
    }
    margin-top: 21px;
  }

  .error-message {
    padding-top: 20px;
    color: $error-red;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
  }
}