.nts-xls-file-upload {
    .file-button-container {
        width: 100%;
        height: 5em;
    }
    .file-button {
        top: 30%;
        left: 50%;
        transform: translate(-50%, -30%);
        position: relative;
        width: 15em;
        height: 5em;
        background: #eeee;
    }
    
    .file-icon {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        height: 70%;
        width: 70%;
    }
    
    .file-name {
        @extend .file-icon;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 5px 0 5px;
        text-align: center;
    }
    
    &.modal {
        z-index: 5000;
        transform: translate(5%, 22%);
        .modal-dialog {
            max-width: 25em;
        }   
    }
}
