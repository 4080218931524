.spinner-container {
  background: #2D2D2D;
  opacity: 0.8;
  padding: 10px;
  border-radius: 6px;
  width: 84px;
  height: 84px;
  position: fixed;
  top: calc(50% - 42px);
  left: calc(50% - 42px);
  z-index: 10000
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


.lds-roller-small {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-roller-small div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 16px 16px;
}
.lds-roller-small div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}
.lds-roller-small div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller-small div:nth-child(1):after {
  top: 25px;
  left: 25px;
}
.lds-roller-small div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller-small div:nth-child(2):after {
  top: 27px;
  left: 22px;
}
.lds-roller-small div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller-small div:nth-child(3):after {
  top: 28px;
  left: 20px;
}
.lds-roller-small div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller-small div:nth-child(4):after {
  top: 29px;
  left: 16px;
}
.lds-roller-small div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller-small div:nth-child(5):after {
  top: 28px;
  left: 12px;
}
.lds-roller-small div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller-small div:nth-child(6):after {
  top: 27px;
  left: 10px;
}
.lds-roller-small div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller-small div:nth-child(7):after {
  top: 25px;
  left: 7px;
}
.lds-roller-small div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller-small div:nth-child(8):after {
  top: 22px;
  left: 5px;
}
