@import '../../css/global/variables.scss';

.landing {

    IMG {
        max-width: 100%;
        margin: 0 auto;
    }
    .primary-banner {
        background-position: center;
        height: 525px;
        background-size: cover;
        padding-top: 20%;
        padding-bottom: 20%;
        &.mobile {
            display:block;
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            @media ($screen-md-min) {
                display:none;
            }
        }
        &.desktop {
            display:none;
            @media ($screen-md-min) {
                display:block;
                background-repeat: no-repeat;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }
    }
    @media ($screen-xl-min) {
        .container {
            padding-left: 85px;
            padding-right: 85px;
        }
    }
    .default-sub-header {
        text-align:center;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    .default-header {
        font-size: 26px;
        line-height: 26px;
        letter-spacing: -0.72px;
        text-align: center;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .default-xl-header {
        font-size: 26px;
        letter-spacing: -0.72px;
        text-align: center;
        padding: 40px 0 40px 0;
        text-transform: uppercase;
    }
    @media($screen-sm-min) {
        .default-sub-header {
            text-align:left;
            font-size: 30px;
            line-height: 30px;
            margin-bottom: 40px;
        }
        .default-header {
            font-size: 42.5px;
            line-height: 42.5px;
            margin-bottom: 40px;
        }
        .default-xl-header {
            font-size: 60px;
            line-height: 60px;
            padding: 100px 0 85px 0;
        }
    }
    .section-title {
        text-align:center;
        font-size: 18px;
        margin: 0 0 15px;
        text-transform: uppercase;
    }
    .section-img {
        margin-bottom: 20px;
    }
    .section-summary {
        font-family: $font-primary;
        text-align: center;
        font-size: 15px;
    }
    @media($screen-sm-min) {
        .section-title {
            font-size: 24px;
            margin: 20px 0 30px;
        }
        .section-summary {
            width:80%;
            margin: 0 auto;
        }
    }
    .cta-container {
        padding: 20px 0;
        text-align:center;
    }
    .cta-button {
        display:inline-block;
        padding: 12px 21px;
        text-align:center;
        border: 1px solid $accent-gray;
        height: 40px;
        background-color: white;
        text-transform:uppercase;
        font-size: 15px;
        line-height:16px;
        min-width: 110px;
        color: $black;
        cursor: pointer;
        margin: 0 10px;

        &:hover {
            color: $black;
            background-color: $accent-gray;
        }
    }
    .cta-link {
        cursor: pointer;
        text-transform: uppercase;
        font-size: 15px;
        color: $black;
        &:hover {
            color: $black;
            text-decoration: underline;
        }
    }
    .cta-header {
        cursor: pointer;
        text-transform: uppercase;
        font-size: 15px;
        color: $black;
        font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
    }

    .cta-desc {
        padding-top: 20px;
        font-family: $font-primary;
        font-size: 12px;
        text-align: center;
    }

    .landing-subnav-container {
        text-align: center;

        font-family: $font-primary;
        .landing-subnav {
            margin-bottom: 65px;
            display:inline-block;
            LI {
                border-right: 1px solid $med-gray;
                &:last-of-type {
                    border-right: none;
                }

                A {
                    color: $dark-gray;

                    font-size: 16px;
                    padding: 0 30px;
                    &:hover, &:active, &:focus {
                        background-color:transparent;
                        color: $dark-gray;
                    }
                }

            }
        }
        .mobile-subnav {
            text-align: center;
            margin-left: 0;
            margin-right:0;
            margin-bottom: 20px;
            overflow: hidden;
            .col-xs-6 {
                padding: 0;
                .subcat-link {
                    display: block;
                    cursor: pointer;
                    padding: 30px;
                    background-color: $light-gray;
                    border: 1px solid $accent-gray;
                    border-top: 0px solid transparent;
                    color: $dark-gray;
                    min-height: 100px;
                    &:hover {
                        background-color:white;
                    }
                }
                &:nth-of-type(1), &:nth-of-type(2) {
                    .subcat-link {
                        border-top: 1px solid $accent-gray;
                    }
                }
                &:nth-of-type(2n) {
                    .subcat-link {
                        border-left: 0px solid transparent;
                    }
                }
            }
        }
    }
    .horiz-rule {
        border-bottom: 1px solid $accent-gray;
        margin-bottom: 40px;
    }
    .landing-row {
        .landing-col {
            margin-bottom: 50px;
            text-align: center;
        }

    }
    .full-look-row {
        IMG {
            width: 100%;
        }
    }
    .featured-product-row {
        padding-bottom: 30px;
        text-align:center;
        .featured-product {
            margin-bottom: 20px;
            IMG {
                width: 100%;
            }
            .product-name {
                padding-top: 20px;
                font-family: $font-primary;
                font-size: 15px;
                text-align: center;
            }
            @media($screen-sm-min) {
                margin-bottom: 0;
            }
        }

    }

    .more-options-row.row {
        margin-bottom: 90px;

        .col-md-4 {
            padding: 0 50px;
        }
        .more-options-container {
            max-width: 300px;
            margin:0 auto 20px auto;
            text-align:center;
            .default-sub-header {
                display: inline-block;
            }
            SELECT {
                width: 100%;
            }
        }
        @media($screen-md-min) {
            .more-options-container {
                text-align:left;
            }
        }
        .catalog-row {
            .catalog-col {
                padding: 0 10px;
                text-align:center;
            }
            .catalog-download .default-sub-header {

                margin-bottom: 0;
            }
        }

        .default-sub-header {
            font-size: 22px;
            margin-bottom:20px;
            margin-top: 30px;
            display: inline-block;

            &.customize-it-bg {
                position: relative;
                &::before {
                    position: absolute;
                    left:0;
                    top:-2px;
                    content: '';
                    width:20px;
                    height:20px;
                    background: url('../../img/colorwheel-thin.svg') no-repeat;
                }
                padding-left: 18px;
            }
            @media($screen-sm-min) {
                &.customize-it-bg {
                    &::before {
                        top:3px;
                    }
                }
            }
        }
    }

    .nts-instruction-flow {
        margin-bottom: 30px;

        .nts-instruction-box {
            border-right: 1px solid $med-gray2;
            min-height: 100px;
            text-align: center;

            @media($screen-md-max) {
                &.box-2 {
                    border: none;
                }
                &.box-3, &.box-4 {
                    margin-top: 15px;
                }
            }

            &:last-child {
                border-right: none;
            }

            .number-icon {
                border: 1px solid $med-gray;
                color: $med-gray;
                -webkit-border-radius: 11px;
                -moz-border-radius: 11px;
                border-radius: 11px;
                width: 22px;
                height: 22px;
                display: inline-block;
                margin: 10px;
                font-size: 12px;
                line-height: 20px;
            }
            .instruction-title {
                font-family: TradeGothicCondensed, TradeGothic, "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 15px;
            }

            .instruction-detail {
                color: $med-gray;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 12px;
            }


        }
    }

}
