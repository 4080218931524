#scheels-landing-page.landing {

    max-width: 1440px;
    min-height: calc(100vh - 295px);
    margin: 0 auto;

    .container {
        margin-bottom: 80px;

        .nts-scheels-team-logo {
            width: 100%;
            text-align: center;
            padding: 40px 0;
            img {
                margin: 0 auto;
                width: 360px;
                height: 158px;
            }
        }

        .nts-scheels-main-banner {
            margin-bottom: 40px;
            img {
                width: 100%;
            }
        }

        .primary-banner {
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            height: 664px;
        }
        
        .nts-instruction-flow {
            margin: 20px 0 60px 0;
            .instruction-title {
                font-size: 20px;
            }
            .instruction-detail {
                font-size: 16px;
            }
        }

        .nts-cta-container {
            @media($screen-sm-max) {
                margin-bottom: 15px;
            }

            .cta-header {
                margin: 16px 0 5px 0;
                font-size: 20px;
                @media(max-width:1024px) {
                    min-height: 56px;
                }
            }
            .cta-desc {
                text-align: left;
                padding-top: 0;
                min-height: 40px;
                color: $med-gray;
                font-size: 16px;
                line-height: 20px;
                max-width: 250px;

                @media (max-width:976px) {
                    min-height: 66px;
                }
            }
            .cta-button {
                margin-left: 0;
            }
        }

    }

}