/*
 * FONTS USED FOR DESIGN LAYOUTS (NOT SITE TEXT)
 */

@font-face {
  font-family: 'bureauagencyregular';
  font-display: swap;
  src: url('../fonts/text/bureaage-webfont.woff2') format('woff2'),
  url('../fonts/text/bureaage-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
#btn-add-factory
{
  display: flex;
    align-items: center;
    justify-content: space-between;

}

@font-face {
  font-family: 'FutureCourt-SemiBold';
  font-display: swap;
  src: url('../fonts/text/FutureCourt-SemiBold.woff2') format('woff2'),
  url('../fonts/text/FutureCourt-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'fenwayparkjfregular';
  font-display: swap;
  src: url('../fonts/text/fenwayparkjf-webfont.woff2') format('woff2'),
  url('../fonts/text/fenwayparkjf-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bureauagencynumber';
  font-display: swap;
  src: url('../fonts/number/bureau_ii_4to5in_1c_6-23-14-webfont.woff2') format('woff2'),
  url('../fonts/number/bureau_ii_4to5in_1c_6-23-14-webfont.woff') format('woff');
}

@font-face {
  font-family: 'FuturConExtBold';
  font-display: swap;
  src: url('../fonts/text/futurconextbol-webfont.woff2') format('woff2'),
  url('../fonts/text/futurconextbol-webfont.woff') format('woff');
}

@font-face {
  font-family: 'FuturConExtBoldItalic';
  font-display: swap;
  src: url('../fonts/text/futurconextbol-webfont.woff2') format('woff2'),
  url('../fonts/text/futurconextbol-webfont.woff') format('woff');
}

@font-face {
  font-family: 'kansasregular';
  font-display: swap;
  src: url('../fonts/text/kansas-webfont.woff2') format('woff2'),
  url('../fonts/text/kansas-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kansas_n_1cregular';
  font-display: swap;
  src: url('../fonts/text/kansas_n_1c-webfont.woff2') format('woff2'),
  url('../fonts/text/kansas_n_1c-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'michigan_fill_2010regular';
  font-display: swap;
  src: url('../fonts/text/michigan_fill_2010-webfont.woff2') format('woff2'),
  url('../fonts/text/michigan_fill_2010-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'michigan_number';
  font-display: swap;
  src: url('../fonts/number/michigan_ii_4to5in_1c_6-20-14-webfont.woff2') format('woff2'),
  url('../fonts/number/michigan_ii_4to5in_1c_6-20-14-webfont.woff') format('woff');
}

@font-face {
  font-family: 'full_block_nine_to_twelveRg';
  font-display: swap;
  src: url('../fonts/text/fullblonintotwesol-webfont.woff2') format('woff2'),
  url('../fonts/text/fullblonintotwesol-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'full_block_number';
  font-display: swap;
  src: url('../fonts/number/fullblock_ii_4to5in_1c_6-20-14-webfont.woff2') format('woff2'),
  url('../fonts/number/fullblock_ii_4to5in_1c_6-20-14-webfont.woff') format('woff');
}

@font-face {
  font-family: 'NikeUncontestedRegular';
  font-display: swap;
  src: url('../fonts/text/nikeuncontested-webfont.woff2') format('woff2'),
  url('../fonts/text/nikeuncontested-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Tuscon-CondensedBold';
  font-display: swap;
  src: url('../fonts/text/Tuscon-CondensedBold.woff2') format('woff2'),
  url('../fonts/text/Tuscon-CondensedBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'tuscon_number';
  font-display: swap;
  src: url('../fonts/number/tucson_ii_4to5in_1c_6-25-14-webfont.woff2') format('woff2'),
  url('../fonts/number/tucson_ii_4to5in_1c_6-25-14-webfont.woff') format('woff');
}

@font-face {
  font-family: 'UnitedSansCdHv';
  font-display: swap;
  src: url('../fonts/text/UnitedSansCdHv.woff2') format('woff2'),
  url('../fonts/text/UnitedSansCdHv.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'UnitedSansSemiCondBold';
  font-display: swap;
  src: url('../fonts/text/UnitedSansSemiCond-Bold.woff2') format('woff2'),
  url('../fonts/text/UnitedSansSemiCond-Bold.woff') format('woff');
}

@font-face {
  font-family: 'AkzidenzGroteskBE-Bold';
  font-display: swap;
  src: url('../fonts/text/AkzidenzGroteskBE-Bold.woff2') format('woff2'),
  url('../fonts/text/AkzidenzGroteskBE-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AkzidenzGroteskBE-Md';
  font-display: swap;
  src: url('../fonts/text/AkzidenzGroteskBE-Md.woff2') format('woff2'),
  url('../fonts/text/AkzidenzGroteskBE-Md.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gridiron';
  font-display: swap;
  src: url('../fonts/text/gridiron.woff2') format('woff2'),
  url('../fonts/text/gridiron.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nike-fs-lacrosse-italic';
  font-display: swap;
  src: url('../fonts/text/nike-fs-lacrosse-italic.woff2') format('woff2'),
  url('../fonts/text/nike-fs-lacrosse-italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nike-fs-lacrosse';
  font-display: swap;
  src: url('../fonts/text/nike-fs-lacrosse.woff2') format('woff2'),
  url('../fonts/text/nike-fs-lacrosse.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UnitedSansSmCdMd';
  font-display: swap;
  src: url('../fonts/text/UnitedSansSmCdMd.woff2') format('woff2'),
  url('../fonts/text/UnitedSansSmCdMd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Univers-BlackExt';
  font-display: swap;
  src: url('../fonts/text/Univers-BlackExt.woff2') format('woff2'),
  url('../fonts/text/Univers-BlackExt.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FutureCourt-Bold';
  font-display: swap;
  src: url('../fonts/text/FutureCourt-Bold.woff2') format('woff2'),
  url('../fonts/text/FutureCourt-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FutureCourt-Medium';
  font-display: swap;
  src: url('../fonts/text/FutureCourt-Medium.woff2') format('woff2'),
  url('../fonts/text/FutureCourt-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'monofonto';
  font-display: swap;
  src: url('../fonts/text/monofonto.woff2') format('woff2'),
  url('../fonts/text/monofonto.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nike-fs-baseball';
  font-display: swap;
  src: url('../fonts/text/nike-fs-baseball.woff2') format('woff2'),
  url('../fonts/text/nike-fs-baseball.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nike-fs-football';
  font-display: swap;
  src: url('../fonts/text/nike-fs-football.woff2') format('woff2'),
  url('../fonts/text/nike-fs-football.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avantgarde-Bold';
  font-display: swap;
  src: url('../fonts/text/Avantgarde-Bold.woff2') format('woff2'),
  url('../fonts/text/Avantgarde-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Diamond-Heavy-SF-Bold';
  font-display: swap;
  src: url('../fonts/text/Diamond-Heavy-SF-Bold.woff2') format('woff2'),
  url('../fonts/text/Diamond-Heavy-SF-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SkulCollegiateSans';
  font-display: swap;
  src: url('../fonts/text/SkulCollegiateSans.woff2') format('woff2'),
  url('../fonts/text/SkulCollegiateSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGotBolConTweObl';
  font-display: swap;
  src: url('../fonts/text/TradeGotBolTwoObl.woff2') format('woff2'),
  url('../fonts/text/TradeGotBolTwoObl.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SkullRBSans';
  font-display: swap;
  src: url('../fonts/text/SkulRBsans.woff2') format('woff2'),
  url('../fonts/text/SkulRBsans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SkullNavySans';
  font-display: swap;
  src: url('../fonts/text/SkulNavySans.woff2') format('woff2'),
  url('../fonts/text/SkulNavySans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NikeTradePlus';
  font-display: swap;
  src: url('../fonts/text/NikeTradePlus.woff2') format('woff2'),
  url('../fonts/text/NikeTradePlus.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGothicforNike365-BdCn';
  font-display: swap;
  src: url('../fonts/text/TradeGothicforNike365-BdCn.woff2') format('woff2'),
  url('../fonts/text/TradeGothicforNike365-BdCn.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Impact';
  font-display: swap;
  src: url('../fonts/text/Impact.ttf') format('truetype'),
  url('../fonts/text/Impact.woff') format('woff'),
  url('../fonts/text/Impact.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SUPERBOWL-HEAVY-SOLID';
  font-display: swap;
  src: url('../fonts/text/SUPERBOWL-HEAVY-SOLID.otf') format('opentype'),
  url('../fonts/text/SUPERBOWL-HEAVY-SOLID.woff') format('woff'),
  url('../fonts/text/SUPERBOWL-HEAVY-SOLID.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'STENCILOBLIQUEALPHA-BdObl';
  font-display: swap;
  src: url('../fonts/text/STENCILOBLIQUEALPHA-BdObl.woff2') format('woff2'),
  url('../fonts/text/STENCILOBLIQUEALPHA-BdObl.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CHANEY-UltraExtended';
  font-display: swap;
  src: url('../fonts/text/CHANEY-UltraExtended.woff2') format('woff2'),
  url('../fonts/text/CHANEY-UltraExtended.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
