#contact-modal {
  .modal-header {
    font-size: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px
  }
  .modal-body{
    height: 100%;
    overflow: auto;
    font-size:  16px;
  }
  .row {
    margin-left: 0;
    margin-right: 10px
  }
  .recommended-container {
    margin-top: 15px;
    h2 {
      margin-top: 10px;
    }
    hr {
      margin-top: 5px;
    }
    ul {
      li {
        display: block;
        padding-bottom: 10px;
        .location-container {
          display: inline-block;
          width: 100%
        }
      }
    }
  }
}