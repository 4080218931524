.nts-admin-factory-page {

  .admin-tabs-content {
    padding: 20px !important;

    .nts-table {
        .rt-tbody {
          .rt-tr-group {
            &:last-child{
              border-bottom: 1px solid #e5e5e5 !important;
            }
            .rt-tr {
              cursor: default;
              .rt-td {
                .nts-factory-row-wrapper{
                  display: flex;
                  justify-content: space-between;
                  input{
                    width: 90% !important;
                  }
                }
                .icon{
                  margin-right: 20px;
                }
                .delete-icon {
                  &:hover {
                    cursor: pointer;
                  }
                  position: relative;
                  height: 25px;
                  width: 25px;
                  content: url('../../../../img/delete_icon.png');
                }
                .edit-icon {
                  &:hover {
                    cursor: pointer;
                  }
                  position: relative;
                  height: 25px;
                  width: 25px;
                  content: url('../../../../img/edit_icon.png');
                }
                .accept-icon {
                  &:hover {
                    cursor: pointer;
                  }
                  position: relative;
                  height: 25px;
                  width: 25px;
                  content: url('../../../../img/accept_icon.png');
                }
                .cog-icon {
                  &:hover {
                    cursor: pointer;
                  }
                  position: relative;
                  height: 25px;
                  width: 25px;
                  content: url('../../../../img/cog_icon.png');
                }
              }
            }
          }
        }
      }

    .error {
      color: red;
      margin: 5px 0 15px 0;
    }

  }

  
}
.nts-factory-modal{
  .mr-15{
    margin-right: 15px;
  }
}
.nts-back-list{
  font-family: $font-secondary; 
  margin: 0px 0px 0px 10px;
  font-size: 16px;
  line-height: 15px;
  text-align: left;
  padding-bottom: 5px;
  padding-top: 15px;
  color: $black;
  white-space: nowrap;
  display: block;
  position: relative;
  padding-left: 15px;
  &:before {
    content: url('../../../../img/SVG/chevron-left-solid.svg');
    background-size: 10px 20px;
    width: 10px;
    height: 20px;
    position: absolute;
    top: 14px;
    left: 0px;
  }
}
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  // box-shadow: inset 0 -3px 0 0 rgb(255 0 0 / 60%) !important;
  &:before {
    content: "▼";
    float: right;
  }
}
.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  // box-shadow: inset 0 3px 0 0 rgb(255 0 0 / 60%) !important;
  &:before {
    content: "▲";
    float: right;
  }
}
.nts-factory-style-modal{
  .modal-lg {
    width: 1200px !important;
    max-width: 1200px !important;
  }
} 
.nts-tab-search-results input {
  width: 120px !important;
}
