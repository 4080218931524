#nts-main-landing {

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
  }

  .header {
    margin: 40px 0;
    text-align: center;
    font-family: TradeGothicCondensed, TradeGothic, "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    z-index: 1;
  }

  .nts-video-container {
    text-align: center;
    margin-bottom: 40px;
    font-size: 15px;
    p {
      font-family: TradeGothicCondensed, TradeGothic, Arial, sans-serif;

    }

    .nts-video-iframe {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
      max-width: 1200px;
      margin: auto;
      @media($screen-lg-min) {
        padding-bottom: 500px;
        padding-top: 215px;
      }
    }

    .nts-video-iframe iframe,
    .nts-video-iframe object,
    .nts-video-iframe embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .nts-instruction-flow {
    margin-bottom: 30px;

    .nts-instruction-box {
      border-right: 1px solid $med-gray2;
      min-height: 100px;
      text-align: center;

      @media($screen-md-max) {
        &.box-2 {
          border: none;
        }
        &.box-3, &.box-4 {
          margin-top: 15px;
        }
      }

      &:last-child {
        border-right: none;
      }
      
      .number-icon {
        border: 1px solid $med-gray;
        color: $med-gray;
        -webkit-border-radius: 11px;
        -moz-border-radius: 11px;
        border-radius: 11px;
        width: 22px;
        height: 22px;
        display: inline-block;
        margin: 10px;
        font-size: 12px;
        line-height: 20px;
      }
      .instruction-title {
        font-family: TradeGothicCondensed, TradeGothic, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 15px;
      }

      .instruction-detail {
        color: $med-gray;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 12px;
      }


    }
  }

  .sub-header {
    text-align: center;
    margin: 0 0 25px 0;
    border-bottom: none;
    font-size: 26px;
  }

  .nts-cta-container {
    @media($screen-sm-max) {
      margin-bottom: 15px;
    }

    .cta-header {
      margin-top: 15px;
      font-size: 20px;
      @media(max-width:1024px) {
        min-height: 56px;
      }
    }
    .cta-desc {
      text-align: left;
      padding-top: 0;
      min-height: 40px;
      @media (max-width:976px) {
        min-height: 66px;
      }
    }
    .cta-button {
      margin-left: 0;
    }
  }

  .horiz-rule {
    margin-top: 40px;
  }

  
  .nts-homepage-message-section {
    margin-bottom: 40px;
    color: #000;
    font-family: TradeGothicCondensed, TradeGothic, Helvetica, Arial, sans-serif;
    text-align: center;

    .nts-homepage-message-banner {
      div {
        max-width: 1440px;
        margin: 0 auto;
        border-radius: 2px;

        @media(min-width: 768px) and (max-width: 992px) {
          height: 900px;
        }
        @media($screen-sm-max) {
          max-width: calc(100vw - 20px);
        }
      }

      button {
        position: absolute;
        bottom: 30px;
        left: calc(50% - 55px);
        line-height: 40px;
      }
    }

    .nts-covid-message-container {
      max-width: 700px;
      margin: 0 auto;

      h2 {
        margin: 30px 0 20px 0;
      }

      p {
        font-size: 16px;
        margin: 20px 0;
      }

      p.terms-text {
        font-family: $font-primary;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .nts-join-us-message-container {
      margin: 0 auto;
      text-align: center;

      h1 {
        font-size: 44px;
        font-family: FuturConExtBold, TradeGothic, Helvetica, Arial, sans-serif;
        margin: 20px 0 10px 0;
      }

      p {
        font-family: $font-primary;
        font-size: 12px;
        line-height: 18px;
        max-width: 450px;
        margin: 0 auto;
      }

      .cta-container {
        padding: 0;

        .cta-button {
          min-width: auto;
          width: 80px;
          margin-top: 20px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          background-color: #111111;
          border-color: #111111;
          color: #ffffff;
          padding: 0;
          background-image: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          text-decoration: none;
        }

      }

    }

  }

}