.nts-custom-colors-page {
  min-height: calc(100vh - 297px);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-y: auto;
  padding-top: 20px;

  .title {
    font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .custom-colors-action-row {
    line-height: 40px;
    margin-bottom: 30px;

    .col-* {
      margin-bottom: 10px;
    }

    button {
      margin-right: 40px;
      border-radius: 0;
      padding: 0 40px;
    }

    .input-container {
      position: relative;
      display: inline-block;

      i {
        position: absolute;
        left: 10px;
        color: #000;
        font-size: 20px;
      }

      input[type="text"] {
        font-size: 14px;
        border-radius: 0;
        height: 40px;
        border: 1px solid #ccc;
        padding: 5px 5px 5px 30px;
        &:before {
          content: url('../../img/SVG/Chevron_down.svg');
          position: absolute;
          top: 1px;
          right: 2px;
        }
      }
    }

    label {
      font-weight: normal;
      float: right;
      input[type="checkbox"] { }
    }

  }

  .custom-colors-grid {
    margin-bottom: 30px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    column-gap: 40px;
    row-gap: 40px;

    .page-error {
      grid-column: 1 / -1;
      font-size: 28px;
      padding-top: 10px;
    }

    .custom-colors-tile {
      padding: 10px;
      text-align: center;

      &:hover {
        border: 1px solid $med-gray2;
        -moz-box-sizing:border-box;
        -webkit-box-sizing:border-box;
        box-sizing:border-box;
        margin: -1px;
        cursor: pointer;
      }

      .color-preview {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        display: inline-block;
        margin: 10px auto;
      }

      .details {
        text-transform: uppercase;
        margin-bottom: 20px;
        h5 { font-weight: bold; }
        div { font-size: 13px; }
      }

    }

  }

  /*** Mobile Stylings - Small Screen ***/
  @media ($screen-sm-max) {
    .custom-colors-action-row {
      button, input {
        margin-bottom: 20px;
      }
      label {
        float: none;
      }
    }
  }

}

/**
 * Add Custom Font Modal
 */
.nts-custom-color-upload-modal {
  @extend .nts-message-modal;

  .modal-dialog {
    max-width: 500px;
    .modal-content {
      padding: 20px;

      .modal-header {
        margin: 15px 0;
        text-transform: uppercase;
      }

      .custom-colors-form {
        text-align: left;

        label {
          width: 100%;
          margin-bottom: 20px;
          position: relative;

          span {
            display: inline-block;
            width: 25%;
            text-transform: uppercase;
          }

          span.circle-add {
            transform: rotate(45deg);
            width: 20px;
            position: absolute;
            right: -10px;
            top: -9px;

            &:hover {
              border-color: #000;
              color: #000;
            }

          }

          input {
            display: inline-block;
            width: 75%;
            font-weight: normal;
          }

          input[type="checkbox"] {
            margin: 0;
            float: none;
            width: 0;
          }

          button {
            height: 30px;
          }

          input.error, button.error {
            border-color: red;
          }

        }

        label.color-input-label {
          span {
            width: 50%;
          }
          input {
            width:  calc(50% - 38px);
          }
          span.color-swatch {
            width: 24px;
            height: 24px;
            display: inline-block;
            border: 1px solid $med-gray2;
            margin: 0 5px;
            float: right;
          }
        }
      }
      p.error {
        text-align: left;
        color: $red;
        margin: 5px 0;
      }

      .modal-footer {
        border-top: none;
        button {
          padding: 0 40px;
          margin-left: 20px;
        }
      }

    }
  }

  /*** Mobile Stylings - Small Screen ***/
  @media ($screen-sm-max) {

  }

}

/**
 * Font Details (Update) Modal
 */
.nts-custom-color-details-modal {
  @extend .nts-message-modal;

  .modal-dialog {
    max-width: 300px;
    margin: 20px auto;
    .modal-header {
      button.close { top: 10px; }
    }
    .modal-body {
      margin: 10px 0;
      text-align: left;
      img {
        display: block;
        margin: 0 auto;
      }
      .color-details {
        margin: 20px 0;
        strong {
          text-transform: uppercase;
        }
      }
      .color-preview-wrapper {
        width: 100%;
        text-align: center;
        margin: 20px 0;

        .color-preview {
          height: 100px;
          width: 100px;
          border-radius: 50%;
          display: inline-block;
          margin: 10px auto;
        }
      }
      label.update-color-input-label {
        span {
        width: 70%;
        text-transform: uppercase;
        font-size: 12px;
        }
        input {
          width: 30%;
        }
        
        span.color-swatch {
          width: 24px;
          height: 24px;
          display: inline-block;
          border: 1px solid $med-gray2;
          float: right;
          margin-right: 20px;
        }
      }
      .color-code-details {
        text-transform: uppercase;
        font-size: 12px;
      }
      p.error {
        color: $red;
        margin: 10px 0 0 0;
        overflow-wrap: break-word;
      }
    }
    .modal-footer {
      text-align: center;
      border-top: none;
      button {
        padding: 0 40px;
        margin-left: 15px;
      }
    }
  }

}
