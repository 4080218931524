.fillin-helper-modal {
    @media ($screen-md-min) {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .modal-dialog {
        @media ($screen-sm-max) {
        margin: 0;
        }
    }

    .modal-title {
        font-size: 32px;
    }
    .modal-body {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;

        li::marker {
        font-weight: bold;
        }
    }
    .modal-footer {
        &::before {
            display: none;
        }
        &::after {
            content: none;
        }
        border-top: 0;
        display: grid;
        grid-column-gap: 20px;
        grid-auto-flow: column;
    }
    button.close { font-size: 20px; }
}