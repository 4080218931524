.iq-tool-page {
    min-height: calc(100vh - 296px);
    font-family: Helvetica Neue;
    font-size: 16px;
    color: #757575;
    overflow-y: auto;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;

    .heading {
        font-family: TradeGothicCondensed, TradeGothic, sans-serif;
        color: #111111;
        font-size: 32px;
        margin-bottom: 25px;
        margin-top: 20px;
        text-transform: uppercase;
    }

    .search-form-note {
        margin-bottom: 20px;
    }

    .search-form {
        display: grid;
        grid-column-gap: 12px;
        grid-row-gap: 24px;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 25px;
        position: relative;

        .search-text-field {
            border-radius: 8px;
            border: 1px solid #757575;
            height: 60px;
            padding: 10px 14px;

            .search-field-label {
                font-size: 12px;
            }

            input.search-input {
                border: 0;
                color: #111111;
                font-size: 16px;
                width: 100%;
                padding: 0;
            }
        }

        .react-autosuggest__container::before {
            display: none;
        }

        .react-autosuggest__suggestions-container--open {
            position: absolute;
            padding-bottom: 0;
            top: 60px;

            .react-autosuggest__suggestions-list {
                list-style-type: none;
            }
        }
    }

    .search-form-buttons {
        border-bottom: 1px solid #e5e5e5;
        display: grid;
        grid-template-columns: repeat(2, auto) 1fr;
        grid-column-gap: 20px;
        margin-bottom: 35px;
        padding-bottom: 25px;
    }

    .search-results-label {
        color: #111111;
        display: flex;
        font-size: 18px;
        justify-content: space-between;
        margin-bottom: 25px;
        text-transform: capitalize;
    }

    .columns-dropdown-menuitem {
        align-items: center;
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 10px;
        justify-content: start;
        pointer-events: none;

        .nike-check {
            margin: 4px 0;
        }

        label {
            color: black;
            font-family: Helvetica;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            text-transform: uppercase;
        }
    }

    .search-results-table {
        border: 0;
        margin-bottom: 45px;

        .headerRow {
            border-bottom: 1px solid #e5e5e5;
            box-shadow: none;
        }

        .headerCell {
            border: 1px solid #e5e5e5;
            color: black;
            font-family: TradeGothicforNike365-BdCn;
            font-size: 14px;
            text-transform: uppercase;
            text-align: start;
            align-items: center;
            display: flex;

            .icon-asc, .icon-desc {
                display: none;
                margin-right: 5px;
            }


                i {
                    font-size: 20px;
                    line-height: 18px;
                    padding: 0 5px;
                }


        }

        .tableRowGroup {
            border-bottom: 1px solid #e5e5e5;
        }

        .tableCell {
            color: black;
            font-family: Helvetica;
            font-size: 14px;
        }

        .paginationRow {
            border: 0;
            border-top: 1px solid #e5e5e5;
            box-shadow: none;
            padding-top: 30px;
            color: #111111;
            font-family: TradeGothicforNike365-BdCn;
            font-size: 14px;
            text-transform: uppercase;

            .-previous, .-next {
                color: black;
                flex-basis: 200px;
                flex-grow: 0;

                .-btn {
                    text-transform: uppercase;
                }
            }

            .-center {
                justify-content: center;
            }
        }
    }
}
