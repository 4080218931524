.nts-color-help-page {
  min-height: calc(100vh - 295px);
  font-family: TradeGothicCondensed, TradeGothic, Helvetica, Arial, sans-serif;
  padding: 20px;

  .nts-color-palette-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .nts-color-palette {
      text-align: center;
      color: #FFF;
      text-transform: uppercase;
      padding: 20px 0;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      .color-name {
        font-weight: bold;
      }
      .color-value {
        font-weight: normal;
      }
      &:hover, &:active {
        cursor: pointer;
      }
      &:active {
        -webkit-filter: brightness(70%);
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
      }
    }

    @media($screen-sm-max) {
      .nts-color-palette {
        width: 100%;
        height: 80px;
      }
    }

    @media($screen-md-min) {
      width: 450px;
      .nts-color-palette {
        width: 150px;
        height: 150px;
      }
    }

  }

}

.nts-faq-help-page {
  min-height: calc(100vh - 295px);
  background-color: $light-gray;
  padding-bottom: 40px;

  @media($screen-sm-max) {
    background-color: #FFF;
  }

  &.container {
    padding: 0 0 40px 0;
  }

  .nts-faq-title-bar {
    text-align: center;
    font-family: TradeGothicCondensed, TradeGothic, Helvetica, Arial, sans-serif;
    height: 80px;
    background-color: #FFF;
    margin: 0 0 40px 0;
    width: 100%;

    @media($screen-sm-max) {
      margin: 0;
    }

    a {
      width: fit-content;
      display: inline-block;
      &:hover, &:active, &:focus {
        text-decoration: none;
      }
    }

    h1 {
      line-height: 80px;
      text-decoration: none;
      color: $black;

    }

  }

  .nts-faq-container {
    background-color: #FFF;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto 20px auto;
    padding: 20px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-box-shadow: inset 0 1px 0 0 #e5e5e5, inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
    box-shadow: inset 0 1px 0 0 #e5e5e5, inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;

    @media($screen-sm-max) {
      width: 100%;

    }

    .nts-faq-container-title {
      font-family: TradeGothicCondensed, TradeGothic, Helvetica, Arial, sans-serif;
      font-size: 32px;
    }

    .nts-faq-container-subtitle {
      color: $med-gray;
    }

    hr { margin: 15px auto; }

    .nts-faq-nav-topic-container {
      margin-bottom: 30px;

      h3 {
        font-family: TradeGothicCondensed, TradeGothic, Helvetica, Arial, sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        margin: 10px 0;
      }

      a {
        text-decoration: none;
        color: $black;
        display: block;
        font-size: 13px;
        margin: 5px 0;
        &:hover, &:active, &:focus {
          color: $nike-orange;
        }
      }

    }

    &.topic-container {
      padding: 50px;

      @media($screen-sm-max) {
        padding: 25px;
      }

      .nts-faq-container-title {
        margin: 10px 0 40px 0;
        text-transform: uppercase;
      }

    }

    .nts-faq-topic-content {
      color: #7D7D7D;
      margin-top: 20px;

      strong {
        color: $dark-gray;
      }

      p {
        margin: 20px 0;
      }

      img {
        margin: 20px 0;
      }

      a {
        text-decoration: underline;
        color: $black;
        word-break: break-word;
        &:hover, &:active, &:focus {
          color: $nike-orange;
        }
      }

      ul {
        margin-top: -20px;
        li {
          margin-left: 15px;
        }
        &.spaced {
          li {
            margin-bottom: 15px;
          }
        }
      }

    }

    .nts-faq-footer {
      padding: 25px 0;

      .nts-faq-footer-section {
        cursor: pointer;
        text-align: center;

        @media($screen-sm-max) {
          margin-bottom: 20px;
        }

        .nts-faq-footer-icon {
          text-align: center;
          position: relative;

          div {
            font-size: 64px;
            height: 80px;
          }

        }

        h1 {
          font-family: TradeGothicCondensed, TradeGothic, Helvetica, Arial, sans-serif;
          font-size: 24px;
        }

        p {
          color: $med-gray;
          margin: 0;
        }

        span.nts-faq-footer-ln-ext {
          padding-left: 5px;
          @media($screen-md-max) {
            display: block;
            padding-left: 0;
          }
        }

      }
    }

  }


}