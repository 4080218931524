.nts-terms-container {
  min-height: calc(100vh - 295px);
  color: #000;
  font-family: TradeGothicCondensed, TradeGothic, Helvetica, Arial, sans-serif;
  max-width: 900px;
  text-align: center;

  h2 {
    margin: 30px 0 20px 0;
  }

  p {
    font-size: 16px;
    margin: 20px 0;
  }

  p.terms-text {
    font-family: $font-primary;
    font-size: 14px;
    line-height: 24px;
  }

}