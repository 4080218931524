.nts-find-dealer-page {
  padding: 20px;
  font-family: TradeGothicCondensed, TradeGothic, sans-serif;
  min-height: calc(100vh - 295px);

  background-image: url('../../img/map.png');
  background-repeat: no-repeat;
  background-position: 50% 200px;

  @media(max-width: 700px) {
    background-size: contain;
  }

  p {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .nts-dealer-contact-back {
    font-size: 18px;
    margin: 20px 0;
    width: fit-content;
    &:hover {
      color: $dark-gray;
      cursor: pointer;
    }
  }

  .nts-find-dealer-control {
    margin-top: 10px;
    span.nts-find-dealer-label {
      margin-right: 10px;
      vertical-align: top;
      line-height: 36px;
      font-family: TradeGothicCondensed, TradeGothic, sans-serif;
      font-size: 18px;
    }
    div.Select {
      display: inline-block;
      min-width: 250px;
    }
    div.Select-control {
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }
  }

  .nts-dealer-results {

    h2 {
      font-family: TradeGothicCondensed, TradeGothic, sans-serif;
      font-size: 18px;
      text-transform: uppercase;
      border-bottom: 1px solid grey;
      border-top: 1px solid grey;
      padding: 10px 0px 10px 0px;
    }

    hr {
      border-top: 1px solid $med-gray2;
    }

    .nts-dealer-contact-cell {
      margin-top: 15px;
      margin-bottom: 10px;

      div {
        margin-bottom: 5px;
        word-wrap: break-word;
      }

      .nts-dealer-contact-name {
        font-weight: bold;
      }

      .nts-dealer-contact-email {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }

      button {
        width: 100%;
        max-width: 200px;
      }

    }

    .nts-dealer-info {
      color: $dark-gray;
      font-size: 16px;
    }

  }

}