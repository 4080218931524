.nts-lazy-img-error,
.nts-lazy-img-loading {
  width: 100%;
  height: 100%;

  text-align: center;
  position: absolute;

  p {
    font-family: HelveticaNTS, Helvetica, Arial, sans-serif;
    color: #ccc;
    position: relative;
    top: 45%;
  }

}