.nts-catalog-page {
    min-height: calc(100vh - 296px);
    font-family: HelveticaNeue-Medium;
    overflow-y: auto;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;

    .heading {
        color: #111111;
        font-family: TradeGothicCondensed, TradeGothic, sans-serif;
        font-size: 32px;
        margin-bottom: 50px;
        margin-top: 20px;
        text-transform: uppercase;
    }

    .thumbnails-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, 200px);
        grid-gap: 30px;
        padding: 24px 0;
    }

    .catalog-tab-bar {
        display: flex;
        justify-content: space-between;

        .dropdown {
            min-width: 128px;
        }
    }

    .catalog-tabs {
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 35px;
    }

    .catalog-tab {
        color: #757575;
        cursor: pointer;
        font-size: 16px;
        @media($screen-sm-max) {
            font-size: 12px;
        }

        &.selected {
            border-bottom: 2px solid #111111;
            color: #111111;
        }
    }

    .season-title {
        color: #111111;
        font-size: 16px;
    }

    .season-block {
        border-bottom: 1px solid #e5e5e5;
        margin-top: 20px;
    }

    .sportFilter-label {
        text-transform: capitalize;
    }

    .nts-catalog-tile {
        display: grid;
        grid-row-gap: 10px;
        width: 200px;

        img {
            max-width: 100%;
        }

        .nts-catalog-tile-label {
            color: #111111;
            font-size: 14px;
            text-transform: capitalize;
        }
    }
}
