.nts-billing-modal {

  .glyphicon:hover,
  .input-group-addon:hover {
    cursor: pointer;
  }

  .billing-modal-error-section {

    margin: 20px 0;
    text-transform: uppercase;
    text-align: center;

    p {
      color: $error-red;
      font-size: 15px;
    }

  }

  .billing-modal-info-section {

    font-size: 12px;
    font-family: "TradeGothicCondensed", TradeGothic, sans-serif;

    h4 {
      margin: 20px 0;
      font-size: 16px;
      text-transform: uppercase;
    }

    p {
      margin: 0;
    }

  }

}

.nts-quote-page {
  min-height: calc(100vh - 295px);
  margin-bottom: 5px;

  .nts-quote-contact-info {

    hr {
      border-top: 1px solid $accent-gray;
      margin-top: 40px;
    }

    input[type="text"] {
      &:disabled {
        background-color: #FFF;
      }
    }
    .nts-contact-form-error {
      font-size: 15px;
      margin-top: 10px;
      color: $error-red;
    }

    .nts-contact-form-hdr {
      font-family: TradeGothic, Helvetica, Arial, sans-serif;
      font-size: 20px;
      color: #000;
    }

    .nts-form-input-sublabel {
      padding: 10px 0;
      span {
        line-height: 40px;
        font-size: 16px;
      }
      button {
        float: right;
      }
    }

    .nts-form-subtitle {
      font-family: "TradeGothicCondensed", TradeGothic, sans-serif;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .control-label {
      margin-top: 10px;
      text-transform: uppercase;
    }

    .nts-contact-form-dlr-find {
      width: 100%;
      display: inline-block;
      min-height: 40px;
      label {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        float: right;
        line-height: 40px;
        font-weight: normal;
      }
      button {
        float: right;
        margin-left: 5%;
      }
    }

  }

  .cta-btn {
    width: 100%;
    margin-top: 15px;

    @media($screen-sm-min) {
      width: 50%;
      float: right;
    }

  }

}

.actionBar {
  background-color: #f2f2f2;
  padding: 10px 10px;
  .edit-items {
    padding: 20px 10px;
    background-color: #efefef;
  }
}

.quote-actions {
  flex: 1;
  .back-to-cart {
    flex: 1;
    background-color: #dedede;
  }
  .request-quote {
    flex: 1;
  }
}

.contact-info, .message-info, .dealer-info {
  .title {
    font-family: TradeGothic, Helvetica, Arial, sans-serif;
    font-size: 20px;
  }
  .detail-block {
    padding: 10px 0;
    p {
      font-family: TradeGothic, Helvetica, Arial, sans-serif;
      font-size: 15px;
      margin-bottom: 0;
    }
    .bold {
      font-weight: bold;
    }
  }
}

.nts-checkout-container {
  padding-top: 40px;
  min-height: calc(100vh - 405px);
  max-width: 1000px;
  margin: 0 auto 60px auto;

  .checkout-header {
    font-family: TradeGothic, Helvetica, Arial, sans-serif;
    font-size: 30px;
    color: $black;
  }

  .nts-checkout-summary-item {
    color: $white;
    background-color: $black;
    width: 100%;
    max-width: 280px;
    @media($screen-sm-max) {
      max-width: 100%;
    }
  }

  &.coupon-bar-padding {

    .nts-address-block-title {
      font-size: 24px;
    }

    .nts-address-block-text {
      font-size: 14px !important;
    }

    .nts-address-change-link {
      line-height: 40px;
    }

  }

  .actionBar {
    .actionBarTitle {
      font-family: TradeGothic, Helvetica, Arial, sans-serif;
      font-size: 30px;
    }
  }

  .nts-mobile-btn {
    width: 100%;
  }

  .nts-checkout-form-error {
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #FE0000;
  }

}

.nts-retailer-access-code-modal {
  .modal-content {
    top: 250px;
    width: 420px;
    @media($screen-sm-max) {
      width: 100%;
      max-width: 90% !important;
      margin-top: 40px;
    }

    .row {
      margin: auto;
    }

    .modal-header {
      h4 {
        margin-top: 40px !important;
      }

      button span {
        color: black;
        font-size: inherit;
      }
    }

    .modal-body {
      padding-top: 0;

      box-sizing: inherit;
      text-align: center;
      font-size: 14px;
      font-family: $font-primary;

      @media($screen-sm-max) {
        padding: 0 0 15px 0;


      }

      .access-code-entry {
        margin: 20px 0;

        input {
          @extend .nts-input;
          max-width: 210px;
          margin-top: 10px;
          height: 35px;

          &.error {
            border-color: $error-red;
          }
        }

        .errorMessage {
          color: $error-red;
        }
      }

      button {
        width: 100%;
        max-width: 250px;
        margin: 0 auto;

      }
    }

  }

}


.nts-confirmation-page {
  padding-top: 20px;
  min-height: calc(100vh - 295px);
  font-family: TradeGothic, Helvetica, Arial, sans-serif;
  p {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }
}

.nts-dealer-additional-info-form {

  .nts-expanding-input-entry {
    @extend .nts-field-group-form;
    @extend .form-group;

    &:first-of-type {
      margin-top: 0;
    }

    width: 100%;
    position: relative;
    display: inline-block !important;
    margin: 15px 0;

    input {
      @extend .nts-field-group-input;
      width: 100% !important;
      height: 40px;
      border-radius: 0;
      border: 1px solid $med-gray2;
      font-family: "Helvetica", Arial, sans-serif;
      font-size: 14px !important;

      &.form-error {
        border: 1px solid $error-red;
      }
    }
    .circle-minus {
      top: 0;
      right: -38px;
    }
  }

  .nts-add-input-cta {
    max-width: 100%;
    margin: 0 0 10px 0;
  }

  .nts-field-group-form.org-entry-form {
    .nts-field-group-input {

      .Select-control {
        border-radius: 2px;
        margin-bottom: 20px;
        height: 40px;
      }
      input[type="text"] {
        @extend .form-control;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .org-select-container {

    .nts-field-group-input {
      line-height: 40px;
      font-size: 14px;
      font-family: "Helvetica", Arial, sans-serif;
    }
  }

}