.nts-promo-landing-page {

  @extend #grid-container;

  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-height: calc(100vh - 299px);
  padding: 30px;
  margin-bottom: 30px;

  .grid-results-container {
    width: 100%;

    .grid-message {
      text-align: center;
      padding: 20px;

      &.error {
        color: red;
      }
    }

  }
}