/**
 * ----------------------------------------
 * Nike PNG Icons
 * ----------------------------------------
 */
.nike-location-pin-icon {
  &::before {
    position: absolute;
    left: calc(50% - 32px);
    top: 0;
    content: '';
    width: 64px;
    height: 64px;
    background: url('../../img/store-locator.png') no-repeat;
  }
}

.nike-email-icon {
  &::before {
    position: absolute;
    left: calc(50% - 32px);
    top: 0;
    content: '';
    width: 64px;
    height: 64px;
    background: url('../../img/email-icon.png') no-repeat;
  }
}

.user-impersonate-icon {
  &::before {
    position: absolute;
    left: calc(50% - 32px);
    top: 0;
    content: '';
    width: 64px;
    height: 64px;
    background: url('../../img/user_impersonate_icon.png') no-repeat;
  }
}

/**
 * ----------------------------------------
 * Message Modal styles
 * ----------------------------------------
 */
.modal.nts-message-modal {
  .modal-content {
    max-width: 500px;
    text-align: center;
    .modal-header {
      font-size: 30px;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 15px;
      button.close {
        font-size: 20px;
      }
    }
    .modal-body {
      font-family: $font-primary;
      .btn-toolbar {
        float: right;
        margin-top: 20px;
      }
    }
  }
}

/**
 * ----------------------------------------
 * circle icon styles
 * ----------------------------------------
 */
 .circle-base {
  border: 1px solid $dark-gray;
  color: $dark-gray;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 10px;
  font-size: 18px;
  font-family: serif;
  line-height: 18px;
  text-align: center;
  &:hover {
    color: #000;
    cursor: pointer;
  }
}
.circle-add {
  @extend .circle-base;
  &::after {
    content: '\002B'
  }
}
.circle-minus {
  @extend .circle-base;
  &::after {
    content: '\2212'
  }
}

/**
 * ----------------------------------------
 * Nike Sustainability Icon styles
 * ----------------------------------------
 */
 .nike-sustainability-badge {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  border-radius: 40px;
  margin: 5px;
  padding: 5px;
  z-index: 1;

  svg {
    background-size: 22px;
    width: 22px;
    height: 22px;
    margin: auto;
    transform: translate(18%, 18%);
    fill: $black;
  }

  &.small-badge {
    height: 22px;
    width: 22px;
    border-radius: 22px;
    margin: 0;
    padding: 0;

    svg {
      background-size: 16px;
      height: 16px;
      left: 0;
      width: 16px;
      position: absolute;
    }
  }

}

/**
 * ----------------------------------------
 * Color Picker Dots styles
 * ----------------------------------------
 */
 .color-check {
  align-items: center;
  border-radius: 12.5px;
  cursor: pointer;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  margin-bottom: 12px;
  margin-right: 14px;
  overflow: hidden;
  position: relative;
  width: 24px;

  &.selected::after {
    font-family: "nike-glyphs";
    content: "\E611";
    color: #FFFFFF;
    position: absolute;
  }

  &.light.selected::after {
    font-family: "nike-glyphs";
    content: "\E611";
    color: $black;
    position: absolute;
  }

  input[type="checkbox"] {
    visibility: hidden;
    position: absolute;
  }
}

/**
 * ----------------------------------------
 * Color Picker Dots styles
 * ----------------------------------------
 */
.modal.nts-message-modal {
 .modal-content {
   max-width: 500px;
   text-align: center;
   .modal-header {
     font-size: 30px;
     padding-left: 15px;
     padding-right: 15px;
     margin-top: 15px;
     button.close {
       font-size: 20px;
     }
   }
   .modal-body {
     font-family: $font-primary;
     .btn-toolbar {
       float: right;
       margin-top: 20px;
     }
   }
 }
}

/**
 * ----------------------------------------
 * <ExpandingInput> styles
 * ----------------------------------------
 */
 .nts-expanding-input-container {
  input {
    display: inline-block;
  }
  .circle-minus {
    position: absolute;
    top: -4px;
    right: -40px;
  }
  .circle-add {
    margin: 10px 0 0 10px;
  }
  .nts-add-input-cta {
    width: 100%;
    text-align: right;
    margin-top: 10px;
    span {
      display: inline-block !important;
    }
    @media($screen-sm-min) {
      max-width: 300px;
    }
  }
  .nts-expanding-input-entry {
    position: relative;

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }
}

/**
 * ----------------------------------------
 * <AddressDisplay> styles
 * ----------------------------------------
 */
 .nts-address-display {
  min-height: 80px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  position: relative;
  padding: 10px 0;

  .error {
    color: $error-red;
    border: 1px solid $error-red;
    height: 100%;
    min-height: 80px;
    font-size: 14px;
    p {
      position: absolute;
      bottom: -12px;
      margin: 0 !important;
    }
  }
}

/**
* ----------------------------------------
* Base Admin Page styles
* ----------------------------------------
*/
.nts-admin-page {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-height: calc(100vh - 299px);
  padding: 30px;

  h1 {
    font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
    margin-bottom: 30px !important;
  }

  ul.admin-tabs {
    display: inline-block;
    padding: 0;
    margin: 0 !important;
    width: 100%;

    li {
      background-color: #DDD;
      border-color: #DDD;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin: 0 5px 0 0;
      min-width: 50px;
      list-style-type: none;
      padding: 10px;

      &.selected {
        background-color: #FFF;
        border-bottom: none;
      }
    }

  }

  div.admin-tabs-content {
    border: 1px solid #ddd;
    margin-top: -1px;
    padding: 10px;

    .nts-tab-search-bar {
      padding: 5px 0;
      text-align: right;

      label {
        font-weight: normal;
        margin: 0;

        span {
          margin-right: 10px;
        }

        input {
          margin-right: 20px;
          border: 1px solid #ddd;
          border-radius: 0;
          height: 30px;
          width: 250px;
        }

      }
      &.ship-group-search-bar{
        display: flex;
        justify-content: space-between;
      }

      button {
        height: 30px;
        border-radius: 0;
        line-height: 30px;
        margin-top: -4px;
      }

      .error {
        margin: 10px 0 0 0;
      }

    }

    .nts-tab-search-results {
      margin-top: 20px;

      .nts-table {
        .rt-table {
          .rt-thead {
            background-color: $black;
            color: #FFF;
            border: none;
            .rt-th {
              border: none;
            }
          }
        }
      }

    }

    .error {
      color: red;
      margin: 5px 0 15px 0;
    }

  }

}