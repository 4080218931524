.nts-login-msg-container {

  text-align: center;
  margin: 0px 15px -20px 15px;
  font-family: Helvetica, Arial, sans-serif;

  p {
    font-size: 14px;
    color: $error-red;
    margin: 10px 0 20px 0;
  }

}