.nts-custom-fonts-page {
  min-height: calc(100vh - 297px);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-y: auto;
  padding-top: 20px;

  .title {
    font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .custom-fonts-action-row {
    line-height: 40px;
    margin-bottom: 30px;

    .col-* {
      margin-bottom: 10px;
    }

    button {
      margin-right: 40px;
      border-radius: 0;
      padding: 0 40px;
    }

    .input-container {
      position: relative;
      display: inline-block;

      i {
        position: absolute;
        left: 10px;
        color: #000;
        font-size: 20px;
      }

      input[type="text"] {
        font-size: 14px;
        border-radius: 0;
        height: 40px;
        border: 1px solid #ccc;
        padding: 5px 5px 5px 30px;
        &:before {
          content: url('../../img/SVG/Chevron_down.svg');
          position: absolute;
          top: 1px;
          right: 2px;
        }
      }
    }

    label {
      font-weight: normal;
      float: right;
      input[type="checkbox"] { }
    }

  }

  .custom-fonts-grid {
    margin-bottom: 30px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fill, 220px);
    column-gap: 40px;
    row-gap: 40px;

    .page-error {
      grid-column: 1 / -1;
      font-size: 28px;
      padding-top: 10px;
    }

    .custom-fonts-tile {
      padding: 10px;

      &:hover {
        border: 1px solid $med-gray2;
        -moz-box-sizing:border-box;
        -webkit-box-sizing:border-box;
        box-sizing:border-box;
        margin: -1px;
        cursor: pointer;
      }

      img {
        min-width: 200px;
        min-height: 200px;
        margin-bottom: 10px;
        padding: 5px;
      }

      .details {
        text-transform: uppercase;
        overflow-wrap: break-word;
        h5 { font-weight: bold; }
        div { font-size: 13px; }
      }

    }

  }

  /*** Mobile Stylings - Small Screen ***/
  @media ($screen-sm-max) {
    .custom-fonts-action-row {
      button, input {
        margin-bottom: 20px;
      }
      label {
        float: none;
      }
    }
  }

}

/**
 * Add Custom Font Modal
 */
.nts-custom-font-upload-modal {
  @extend .nts-message-modal;

  .modal-dialog {
    max-width: 500px;
    .modal-content {
      padding: 20px;

      .modal-header {
        margin: 15px 0;
        text-transform: uppercase;
      }

      .custom-fonts-form {
        text-align: left;

        label {
          width: 100%;
          margin-bottom: 20px;
          position: relative;

          span {
            display: inline-block;
            width: 25%;
            text-transform: uppercase;
          }

          span.circle-add {
            transform: rotate(45deg);
            width: 20px;
            position: absolute;
            right: -10px;
            top: -9px;

            &:hover {
              border-color: #000;
              color: #000;
            }

          }

          input {
            display: inline-block;
            width: 75%;
            font-weight: normal;
          }

          input[type="checkbox"] {
            margin: 0;
            float: none;
            width: 0;
          }

          button {
            height: 30px;
          }

          input.error, button.error {
            border-color: red;
          }

          .custom-font-file-info {
            display: inline-block;
            width: 75%;
            font-weight: normal;
            div, span {
              display: inline-block;
            }
            div {
              border: 1px solid #CCC;
              height: 30px;
              line-height: 30px;
              padding: 0 6px;
              width: calc(100% - 65px);
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              float: left;
            }
            span {
              width: 64px;
              text-align: right;
              color: $med-gray;
              text-decoration: underline;
              text-transform: lowercase;
              line-height: 30px;
              &:hover {
                color: $black;
                cursor: pointer;
              }
            }
          }

        }

      }

      p.error {
        text-align: left;
        color: $red;
        margin: 5px 0;
      }

      .modal-footer {
        border-top: none;
        button {
          padding: 0 40px;
          margin-left: 20px;
        }
      }

    }
  }

  /*** Mobile Stylings - Small Screen ***/
  @media ($screen-sm-max) {
    .mobile-dialog {
      margin: 20px auto;
      .modal-content {
        .modal-header {
          button.close { top: 10px; }
        }
        .custom-fonts-action-row {
          button, input {
            margin-bottom: 20px;
          }
          label {
            float: none;
          }
        }
      }
    }
  }

}

/**
 * Font Details (Update) Modal
 */
.nts-custom-font-details-modal {
  @extend .nts-message-modal;

  .modal-dialog {
    max-width: 300px;
    margin: 20px auto;
    .modal-header {
      button.close { top: 10px; }
    }
    .modal-body {
      margin: 10px 0;
      text-align: left;
      img {
        display: block;
        margin: 0 auto;
      }
      .font-details {
        margin: 20px 0;
        overflow-wrap: break-word;
        strong {
          text-transform: uppercase;
        }
      }
      p.error {
        color: $red;
        margin: 10px 0 0 0;
        overflow-wrap: break-word;
      }
    }
    .modal-footer {
      text-align: center;
      border-top: none;
      button {
        padding: 0 35px;
        margin-left: 15px;
      }
    }
  }

}
