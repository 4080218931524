#nts-app-container, [role="dialog"] {

    /* Styles for the #nts-stock-builder-app DIV */
    font-family: $font-secondary;
    padding-top: 0;
    margin-top: 70px;
    line-height: 1.4;
    color: $black;
    @media($screen-md-min) {
        margin-top: 110px;
    }

    .btn:focus, button:focus, select:focus, input:focus, *:focus {
        outline: 0;
    }

    //Overrides for react select
    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
        line-height: 40px;
    }

    label input[type="checkbox"] {
        padding: 0;
        margin: 0;
        margin-right: 8px;
        position: relative;
        top: 2px;
        float: left;
    }

    div.checkbox input[type="checkbox"]:after {
        background-color: #FFFFFF;
        background-size: 16px 8px;
        background-position: center center;
        background-repeat: no-repeat;
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        position: absolute;
        line-height: 1;
        top: -1px;
        left: -1px;
    }

    div.checkbox input[type="checkbox"]:checked:after {
        background-image: url("../img/SVG/icon-check.svg");
        background-color: #1b6d85;
    }

    .nts-input {
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width: 100%;
        min-height: 30px;
        margin: 0;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 12px;
    }

    .modal-title {
        text-align: center;
    }

    .modal-header {
        border-bottom: 0;
        padding: 0;
    }

    .modal-lg {
        max-width: 1000px;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    .modal-option-section {
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 15px;
        padding: 10px 0;
        width: 100%;
        font-size: 18px;
        font-family: "TradeGothic-Bold";
        text-align: left;
        text-transform: uppercase;
        color: #000000;

        &.no-border {
            border: none;
        }
    }

    .group-btn-popup__btn {
        border-radius: 0;
        font-size: 16px;
        padding-top: 14px;
        padding-bottom: 14px;
    }

    .save-config {
        background-color: #000000;
        color: #ffffff;
    }

    .save-config:hover {
        background-color: #FA5400;
    }
    .graphic-title {
        font-size: 14px;
        margin-bottom: 5px;
        color: #000000;
        text-transform: uppercase;
        font-family: "TradeGothic-Bold";
    }

    .graphic-segment-title {
        font-size: 1rem;
        border-bottom: 1px solid #ddd;
        margin-bottom: 0px;
        text-transform: uppercase;
    }

    .back-graphic {
        font-size: 16px;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 0px;
        display: inline-block;

        i {
            margin-right: 10px;
            font-size: 14px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .group-form label {
        width: 100%;
        font-size: 16px;
        color: #9B9B9B;
        margin-bottom: 2px;
        font-family: "HelveticaNeue";
    }

    .modal-backdrop.in {
        opacity: 0.5;

        @media($screen-md-max) {
            margin-top: 0;
        }

    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: .5rem;
    }
    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    ol,
    ul,
    dl {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    li {
        line-height:inherit;
    }
    .btn-group {
        .btn:first-of-type {
            margin-left: 0px;
        }
    }

    //Modal classes here are for fixing conflicts with the webapp css.
    .modal.fade.in {
        top: 0px;
    }
    .modal-backdrop.fade.in {
        z-index: 2500;
    }
    .builder-modal-show.fade.in.modal {
        z-index: 2200 !important;
    }
    .show.fade.in.modal {
        z-index: 2600;
    }

    .layoutTabRow {
        margin-left: 0 !important;
    }

    //AT ODD NUMBER PIXELS, PLUGIN HAS HAIRLINE ISSUE. NEEDS THIS FIX.
    .react-scroll-fix > DIV:first-of-type {
        right:-1px !important;
        padding-right: 1px;
    }


    .bootstrap-select.btn-group .dropdown-toggle .caret {
        width: 10px;
        height: 10px;
        border: none;
        background-color: green;
    }

    .nike-check {

        outline: none;
        cursor: default;
        border: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:after {
            font-family: "nike-glyphs";
            content: "\E611";
            color: #FFFFFF;
            border: 1px solid $med-gray2;
            border-radius: 2px;
            padding: 2px;
            font-size: 10px;
            position:relative;
            top: -2px;
            cursor:pointer;
        }

        &:checked:after {
            color: $black;
        }

        &:focus {
            outline: none;
        }

    }


    input[type="radio"] {
        display:none;
    }

    input[type="radio"] + label span {
        display:inline-block;
        width:19px;
        height:19px;
        margin:-1px 4px 0 0;
        vertical-align:middle;
        cursor:pointer;
        -moz-border-radius:  50%;
        border-radius:  50%;
        border: 1px solid #B1B1B1;
    }

    input[type="radio"] + label span {
        background-color: #FFFFFF;
    }

    input[type="radio"]:checked + label span {
        background-color:$nike-orange;
    }

    .has-error .Select-control,
    .has-error .control-label,
    .has-error input,
    .has-error textarea {
        border-color: $error-red;
        color: $error-red;
    }

    /* FROM __STYLES */

    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .float-none {
        float: none !important;
    }


    .section-product {
        padding-top: 60px;
    }

    .separator {
        height: 1px;
        background-color: $accent-gray;
        margin: 10px 0px;
    }

    .nav {
        display: flex;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .box-nav {
        padding: 0 10px;
    }

    .box-nav.slick-current .box-nav__box:before {
        display: block;
    }

    .box-nav__box {
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;
        margin-top: 5px;
        padding: 10px 10px;
        //background-color: #f2f2f2;
        min-height: 60px;
    }

    .box-nav__box img {
        width: auto;
        margin: 0 auto;
    }

    .box-nav__box:before {
        display: none;
        content: "";
        position: absolute;
        background-color: #ffffff;
        right: -5px;
        left: -5px;
        bottom: -5px;
        top: -5px;
        z-index: -1;
        border-radius: 2px;
        border: 1px solid #cccccc;
    }

    .box-nav__box:hover:before {
        display: block;
    }

    .inline-color-wheel {
        top: 1px;
        margin-right: 10px;
    }

    .nts-toast {
        position: fixed;
        padding: 22px;
        font-size: 20px;
        background-color: $near-black;
        border-radius: 0;
        color: #FFFFFF;
        font-family: $font-primary;
        top: 70px;
        left: 0;
        right: 0;
        transform: none;
        text-align: center;
        -webkit-animation: fade-out 3s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        animation: fade-out 3s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        z-index: 10000;
        @media($screen-sm-min) {
            top: 110px;
        }
    }
}

img {
    max-width: 100%;
}

.control-label {
  font-family: $font-secondary;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.shipping-modal {
  height: 95%;
  .modal-content {
    height: 95%;
    .form-group {
      margin-bottom: 15px !important;
      label {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
    }
    .nts-address-type-switch {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
/**
 * ----------------------------------------------
 * Generated by Animista on 2018-5-10 16:24:54
 * w: http://animista.net, t: @cssanimista
 * ----------------------------------------------
 */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
 @-webkit-keyframes fade-out {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 0.9;
  }
  99% {
    opacity: 0;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes fade-out {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 0.9;
  }
  99% {
    opacity: 0;
  }
  100% {
    visibility: hidden;
  }
}

/**
 * ----------------------------------------
 * NTS Form Component Styles
 * ----------------------------------------
 */
.nts-field-group-form {
  min-height: 40px;
  margin: 15px 0;
  padding: 0;
  display: flex;
  label { font-size: 15px; }

  @media($screen-sm-max) {
    display: contents;
  }

  .nts-field-group-label {
    display: inline-block;
    width: 45%;
    max-width: 180px;
    min-height: 40px;
    line-height: 40px;
    @media($screen-sm-max) {
      width: 100%;
      label { vertical-align: bottom; }
    }
  }

  .nts-field-group-input {
    display: inline-block;
    width: 55%;
    min-height: 40px;
    font-size: 15px;
    input, select, textarea,
    input.form-control, select.form-control, textarea.form-control {
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      margin: 0;
    }
    @media($screen-sm-max) {
      width: 100%;
    }
  }
}

/**** POPOVER OVERRIDE - POPOVER RENDERS OUTSIDE MAIN DIV OF APP ****/
#pdp-price-popover {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  &.popover {
    min-width: 320px;
    max-width: 380px;
    z-index: 1;
  }

  .separator {
    height: 1px;
    background-color: $accent-gray;
    margin: 10px 0px;
  }

  .popover-title {
    font-family: $font-secondary;
    font-size: 16px;
    text-transform: uppercase;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 10px 0 4px 0;
    margin: 0 14px;
    background-color: #FFFFFF;
    border-bottom: 2px solid $black;
    display:inline-block;
  }

  .popover-content {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    .price-desc {
      display: inline-block;
      width: 75%;
    }
    .price-value {
      display: inline-block;
      width: 25%;
      text-align: right;
      vertical-align: top;
    }
  }
}

p.required {
    color: red;
}