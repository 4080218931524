/*
 * FONTS USED FOR SITE TEXT
 */

@font-face {
  font-family: 'TradeGothic';
  font-display: swap;
  src:
    url('../fonts/tradegothicltstd-bdcn20-webfont.woff') format('woff'),
    url('../fonts/tradegothicltstd-bdcn20-webfont.ttf') format('truetype'),
    url('../fonts/tradegothicltstd-bdcn20-webfont.ttf') format('ttf'),
    url('../fonts/tradegothicltstd-bdcn20-webfont.svg') format('svg'),
    url('../fonts/tradegothicltstd-bdcn20-webfont.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'futuraregular';
  font-display: swap;
  src: url('../fonts/text/futurconextbol-webfont.woff2') format('woff2'),
    url('../fonts/text/futurconextbol-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  font-display: swap;
  src:
    url('../fonts/HelveticaNeue.ttf') format('ttf'),
    url('../fonts/HelveticaNeue.ttf') format('truetype'),
    url('../fonts/HelveticaNeue.eot'),
    url('../fonts/HelveticaNeue.svg') format('svg'),
    url('../fonts/HelveticaNeue.woff') format('woff'),
    url('../fonts/HelveticaNeue.woff2') format('woff2');
}

@font-face {
  font-family: 'HelveticaNeue-Medium';
  font-display: swap;
  src:
    url('../fonts/HelveticaNeue-Medium-11.ttf') format('ttf'),
    url('../fonts/HelveticaNeue-Medium-11.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  font-display: swap;
  src: url('../fonts/text/Helvetica.ttf') format('truetype'),
  url('../fonts/text/Helvetica.woff') format('woff'),
  url('../fonts/text/Helvetica.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/***** nike-glyphs *****/
@import url('https://assets.commerce.nikecloud.com/ncss/glyphs/2.1/css/glyphs.min.css');
