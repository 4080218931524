#nts-deadlines-page {

  min-height: calc(100vh - 297px);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-y: auto;
  padding-top: 20px;
  margin-bottom: 20px;

  .title {
    font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .disclaimer {
    margin: 10px 0 20px 0;
  }

  .ship-group-section {
    margin-bottom: 25px;

    h2 {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: bold;
      margin: 15px 0 10px 0;
    }

    .style-list-control {
      margin: 10px 0;
      font-size: 12px;
      font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .style-list-section {
    width: max-content;
    border: 1px solid #ddd;
    padding: 20px;

    p {
      display: inline-block;
      margin: 2px 0;
    }

    .divider {
      margin: 0 5px;
    }
  }

  #order-deadlines-table {
    border: 1px solid #000;
    margin: 0;

    p { padding: 0; margin: 0; }

    thead {
      background-color: black;
      color: white;
      font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
      font-size: 15px;

      th {
        width: 25%;
      }
    }
  }

}
.row-alignment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
}
.dropdown-menu {
  border: 1px solid #cccccc;
}
.dropdown-item-container {
  margin-top: -39px;
  width: 15em;
  float: right;
  border: 1px solid #cccccc;
  border-top: 0px;
}
.dropdown-item {
  cursor: pointer;
  padding-left: 5px;
  padding-top: 2px;
  &:hover {
    background-color: #cccccc;
  }
}
.delete-icon {
  &:hover {
    cursor: pointer;
  }
  position: relative;
  height: 20px;
  width: 20px;
  content: url('../../../img/delete_icon.png');
}

.search-bar {
  border: 1px solid #cccccc;
  padding: 5px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
}