#nts-school-admin-page, .org-select-modal {

  h4 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 20px 0;
  }

  .error {
    color: red;
    margin: 5px;
    font-size: 16px;
    font-weight: normal;

    &.search-error {
      font-weight: bold;
    }

    &.update-error {
      text-align: right;
      clear: both;
      padding-top: 20px;
      font-weight: bold;
    }

  }

  .nts-school-search-results {
    margin: 40px 0;

    .ReactTable .rt-table {
      overflow: visible;

      .rt-thead {
        background-color: $black;
        color: #FFF;
        text-transform: uppercase;
        border: none;
        .rt-th {
          border: none;
        }
      }
    }

    .tier_col {
      overflow: visible;
    }

    .button-bar {
      float: right;
      .btn-default {
        margin-right: 30px;
      }
    }

  }

}

.nts-school-search-bar {

  .nts-school-search-bar-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px 0;
    vertical-align: bottom;
    position: relative;

    label {
      width: 29%;
      margin-right: 1%;
      position: relative;


      .nts-school-search-input {
        width: 100%;
        height: 38px;
        line-height: 35px;
        font-weight: normal;

        .Select-control {
          border-radius: 0;
        }

      }

      button {
        height: 38px;
        line-height: 38px;
        position: absolute;
        top: 20px;
      }

    }

    button {
      height: 38px;
      line-height: 38px;
      position: absolute;
      top: 20px;
    }

  }

}