.add-factory-form {
  #communicationMode {
      text-align: left;
      width: 100%;
      margin-top: 0px;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown-menu {
    width: 100%;
  }
}