.org-type-select {
  .Select-menu, .Select-menu-outer {
    max-height: fit-content;
  }
}

.org-select-modal {
  height: auto;

  .modal-header {
    font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
  }

  .modal-content {
    height: auto;
    min-height: 315px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

    .modal-body {
      height: 95%;
      overflow: auto;

      .error {
        color: red;
        font-size: 14px;
        margin-top: 5px;
      }
      .nts-school-search-results {
        margin-top: 25px;

        .nts-school-search-prompt {
          color: red;
          margin-top: 30px;
          text-align: center;
        }
      }

      .nts-school-search-error {
        color: red;
        text-align: center;
      }

      .org-entry-form {
        flex-direction: column;

          label {
            width: 24%;
            margin-bottom: 20px;
            position: relative;
            display: block;

            .nts-school-search-input {
              width: 100%;
              height: 38px;
              line-height: 35px;
              font-weight: normal;

              .Select-control {
                border-radius: 0;
              }

            }

            button {
              height: 38px;
              line-height: 38px;
              position: absolute;
              top: 20px;
            }

          }

        .button-bar {
          padding-top: 10px;

          .btn-primary {
            float: right;
          }
        }

      }
    }

  }

}

.org-select-container {
  .error {
    border: 1px solid red;
  }
}

#org-select-display {
  padding: 15px 0 20px 0;
  p {
    margin: 0;
    padding: 0;
    line-height: 22px;
  }
}

