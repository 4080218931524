.ReactTable.nts-table {
  border: none;
  height: auto;
  margin-bottom: 10px;

  .rt-table {
    min-height: 175px;

    @media($screen-sm-max) {
      overflow: auto;
    }

    .rt-thead {
      border-bottom: 2px solid $accent-gray;
      font-family: TradeGothicCondensed, TradeGothic, "Helvetica Neue", Helvetica, Arial, sans-serif;

      &.-header {
        box-shadow: none;
      }

      .rt-th {
        border: none;
        border-right: 1px solid $accent-gray;
        font-size: 15px;
        padding: 10px 10px;
        text-align: left;

        &:first-child {
          border-left: 1px solid $accent-gray;
        }

        &:focus {
          outline: 0;
        }
      }

    }

    .rt-thead.-filters {
      border-bottom: 1px solid $accent-gray;

      .rt-tr {
        background: $light-gray;
      }

      .rt-th {
        padding: 5px 8px;

        input {
          height: 100%;
          margin-bottom: 0;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }

    .rt-tbody {
      color: #666;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      overflow: visible;

      .rt-tr-group {

        .rt-tr {

          .rt-td {
            border-right: 1px solid $accent-gray;
            padding: 10px 10px;

            &:first-child {
              border-left: 1px solid $accent-gray;
            }
          }

          &:last-child {
            border-right: none;
          }

          &:hover {
            background-color: #DDD;
            cursor: pointer;
          }

        }

      }

    }

  }

  .pagination-bottom {

    .-pagination {
      box-shadow: none;
      border-top: none;
      padding: 10px 5px 5px 5px;

      @media($screen-sm-max) {
        .-center {
          display: none;
        }
        .-previous, .-next {
          button.-btn {
            max-width: 40%;
          }
        }
      }

      .-center {
        text-transform: uppercase;
        flex: none;

        input, select {
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          height: 100%;
        }
      }

      .-previous, .-next {
        button {
          text-transform: uppercase;
          max-width: 200px;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
        }
      }

      .-previous button {
        float: left;
      }

      .-next button {
        float: right;
      }

      .-pageJump {
        input {
          margin: 0;
        }
      }

    }

  }

}