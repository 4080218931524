.nts-footer-outer-container {
    background-color: $black;
    position: relative;
    left:0;
    right: 0;
    bottom: 0;
    @media ($screen-sm-min) {
        padding: 50px 0 15px;
    }
    .nts-footer-inner-container {
        margin: 0 auto;
        max-width: 1070px;
        width: 100%;

        .nts-footer-title-link {
            background-color: transparent;
            border: 0;
            color: white;
            display: block;
            text-transform: uppercase;
            font-size: 14px;
            margin: 10px 0;
            font-family: $font-secondary;
            &.nts-contact-link {
                @media($screen-md-min) {
                    padding-left: 25px;
                }
            }
            @media($screen-sm-max) {
                margin: 0 -20px;
                padding: 15px 20px;
                border-bottom: 1px solid $near-black;
            }
        }
        .nts-footer-links-col {
            @media($screen-sm-max) {
                border-bottom: 1px solid $near-black;
            }
            .nts-footer-title {
                color: white;
                text-transform: uppercase;
                font-size: 14px;
                margin: 15px 0;
                font-family: $font-secondary;
            }
            .nts-footer-link {
                font-family: $font-primary;
                color: $med-gray;
                display: block;
                margin: 10px 0;
                font-size: 12px;
                &:last-of-type {
                    margin-bottom: 15px;
                }
            }
        }
        
        .nts-footer-social-col {
            text-align: right;
            @media($screen-sm-max) {
                text-align: center;
                margin: 30px 0 10px;
                A {
                    margin: 0 8px;
                }
            }
            A {
                margin-left: 15px;
                font-size: 28px;
                color: $dark-gray;
            }
        }
        .nts-copyright-row {
            font-family: $font-primary;
            color: $med-gray;
            font-size: 11px;
            @media($screen-sm-min) {
                margin-top: 50px;
            }
            .nts-copyright-col {
                @media($screen-sm-max) {
                    text-align: center;
                    margin:10px 0;
                }
                .locale-info {
                    color: white;
                    margin-right: 15px;
                    IMG {
                        position: relative;
                        top:-2px;
                        margin-right: 5px;
                    }
                    
                }
            }
            .nts-footer-separator {
                border-top: 1px solid $near-black;
                padding-bottom: 15px;
            }
            .nts-global-nike-links {
                @media($screen-sm-max) {
                    text-align: center;
                    margin:10px 0 30px;
                    A {
                        margin-left: 0;
                        margin: 0 10px;
                    }
                }
                text-align: right;
                A {
                    margin-left: 20px;
                    color: $med-gray;
                }
            }
        }
    }
}